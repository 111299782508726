import styled from 'styled-components';

export const BreadCrumbBody = styled.div`
  color: ${({ theme }) => theme.secondary};
  margin-right: auto;
  margin-bottom: 20px;
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 18px;
  & > a {
    color: ${({ theme }) => theme.primary};
  }
`;
