import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { PrintContainer } from '../styled/containers';
import { PrintBtn } from '../styled/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/logo-itlo.png';
// import logo from '../../assets/images/aht-logo.png';
var Barcode = require('react-barcode');

const PrintComponent: React.FC<any> = ({
    data: {
        serialNumber,
        modelNumber,
        width,
        length,
        nominalPower,
        nominalI,
        firstMessure,
        secondMessure,
        thirdMessure,
        minR,
        maxR,
        qCheck,
    },
}) => {
    const componentRef = useRef(null);

    return (
        <>
            <ReactToPrint
                trigger={() => (
                    <PrintBtn>
                        <FontAwesomeIcon icon={faPrint} /> Етикет
                    </PrintBtn>
                )}
                content={() => componentRef.current}
            />
            <PrintContainer ref={componentRef}>
                <div style={{ display: 'flex' }}>
                    <img src={logo} style={{ height: '40px' }} alt='' />
                    <p style={{ marginLeft: '40px', display: 'inline-block' }}>
                        ИНОВАТИВНИ ТЕХНОЛОГИИ ЗА ЛЪЧИСТО ОТОПЛЕНИЕ ООД
                    </p>
                </div>
                <h2 style={{ marginLeft: 'auto', marginRight: 'auto' }}>нагревателен елемент AHT SSRH</h2>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                    <p>Модел: {modelNumber}</p>
                    <p>Размери: {width + ' см х ' + length + ' см'}</p>
                    <p>Мощност: {'~' + nominalPower + ' W'}</p>
                    <p>Сила на ток: {nominalI + ' A'}</p>
                    <p>Съпротивление: {minR + ' - ' + maxR + ' Ω'}</p>
                    <p>Замервания: {firstMessure + ' Ω | ' + secondMessure + ' Ω | ' + thirdMessure + ' Ω'}</p>
                </div>
                <div>
                    <Barcode value={serialNumber} height={40} displayValue={false} marginLeft={100} marginRight={100} />
                </div>
                <p style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '-10px' }}>SN: {serialNumber}</p>
                {/* <div style={{ display: 'flex' }}> */}
                <p>https://itlo-bg.com</p> <p> Тел. 0876500580, 0879500580</p>
                {/* </div> */}
            </PrintContainer>
        </>
    );
};

export default PrintComponent;
