import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BreadCrumbBody } from '../styled/breadCrumb';

const BreadCrumb: React.FC<any> = ({ adminState: { breadCrumb } }) => {
    const mapUrlToNames = {
        '/': 'Начало',
        '/login': 'Вход',
        '/clients': 'Клиенти',
        '/items': 'Нагревателни елементи',
        '/partners': 'Контрагенти',
        '/users': 'Потребители',
        '/userdetails': 'Потребител',
        '/clientdetails': 'Клиент',
        '/addressdetails': 'Адрес',
        '/roomdetails': 'Помещение',
        '/systemdetails': 'Система',
        '/itemdetails': 'Нагревател',
        '/partnerdetails': 'Контрагент',
        '/item': 'Производство',
        '/model': 'Създай модел',
        '/models': 'Модели',
        '/modeldetails': 'Модел',
        '/client': 'Създай клиент',
        '/user': 'Създай потребител',
        '/data': 'Справки',
        '/room': 'Създай помещение',
        '/system': 'Създай система',
        '/partner': 'Създай контрагент',
        '/eu': 'За платформата',
        '/json': 'Обмен на данни с други системи',
    };
    interface HisObj {
        pathname: keyof typeof mapStateToProps;
        state: any;
    }

    return (
        <BreadCrumbBody>
            {breadCrumb
                .map((e: HisObj, i: number) => (
                    <Link key={i} to={{ pathname: e.pathname, state: e.state }}>
                        {mapUrlToNames[e.pathname]}
                    </Link>
                ))
                .reduce((prev: any, curr: any) => [prev, ' ' + String.fromCharCode(8250) + ' ', curr])}
        </BreadCrumbBody>
    );
};
const mapStateToProps = (state: any) => ({
    adminState: state.adminState,
});
export default connect(mapStateToProps, {})(BreadCrumb);
