import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SideNav = styled.section`
    display: flex;
    flex: 1 0 100%;
    flex-direction: column;

    @media (min-width: 768px) {
        justify-content: flex-start;
        align-items: center;
        position: fixed;
        height: 100%;
        top: 0;
        left: 0;
        max-width: 200px;
        width: 100%;

        -webkit-box-shadow: 4px 1px 9px 1px rgba(0, 0, 0, 0.42);
        box-shadow: 4px 1px 9px 1px rgba(0, 0, 0, 0.42);
    }

    background-color: ${({ theme }) => theme.dark};
`;

export const NavLink = styled(Link)`
    width: 50%;
    flex: row;
    display: flex;
    vertical-align: middle;
    align-items: center;
    color: white;
    text-decoration: none;
    padding: 5px 0;
    & > div {
        width: 20%;
        display: flex;
        justify-content: center;
        align-content: center;
    }
    line-height: 26px;
    & svg {
        height: 18px;
        width: 18px;
        margin-top: -4px;
        align-self: center;
    }
    & > span {
        width: 80%;
        padding-left: 20px;
    }

    &:hover {
        color: ${({ theme }) => theme.secondary};
    }
`;
export const NavA = styled('a')`
    width: 50%;
    flex: row;
    display: flex;
    vertical-align: middle;
    align-items: center;
    color: white;
    text-decoration: none;
    padding: 5px 0;
    & > div {
        width: 20%;
        display: flex;
        justify-content: center;
        align-content: center;
    }
    line-height: 26px;
    & svg {
        height: 18px;
        width: 18px;
        margin-top: -4px;
        align-self: center;
    }
    & > span {
        width: 80%;
        padding-left: 20px;
    }

    &:hover {
        color: ${({ theme }) => theme.secondary};
    }
`;
export const NavLinkContainer = styled.div`
    width: 100%;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    display: flex;
    align-items: flex-start;
    padding: 0 10px;
    font-size: 22px;
    letter-spacing: 0.4px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: space-between;
    @media (min-width: 768px) {
        gap: 10px;
        flex-direction: column;

        margin-top: 70px;
        padding: 0 40px;
        margin-left: -10px;
        & > span {
            padding-left: 20px;
        }
    }
`;
export const MainbarInfo = styled.div`
    font-family: 'Yanone Kaffeesatz', sans-serif;
    z-index: 1;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    letter-spacing: 0.5px;
    padding: 0 27px;
    gap: 10px;
    color: ${({ theme }) => theme.light};
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    background-color: ${({ theme }) => theme.secondary};
    @media (min-width: 768px) {
        width: calc(100% - 200px);
        & div > p > svg {
            margin-right: 10px;
        }
        -webkit-box-shadow: 3px 7px 10px -1px rgba(0, 0, 0, 0.34);
        box-shadow: 3px 7px 10px -1px rgba(0, 0, 0, 0.34);
        position: fixed;
        right: 0;
        top: 0;
        /* font-size: 14px; */
        font-size: 24px;
        align-content: center;
        justify-content: flex-end;
        gap: 40px;
        /* & div {
      flex: 0 0 40%;
      &:last-child {
        flex: 0 0 100%;
        display: flex;
        align-items: flex-end;
      }
    } */
    }
`;

export const Logo = styled.img`
    margin-left: auto;
    margin-right: auto;
    padding: 10px 47px 0 20px;
    height: 70px;
    width: auto;
`;
