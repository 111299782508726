import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SingleItem, AdminState, StateInterface, AuthState, ClientState } from '../../interfaces/StateInterfaces';
import { CardBody } from '../styled/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
interface Data {
    data: SingleItem;
    adminState: AdminState;
    authState: AuthState;
    clientState: ClientState;
}
const ElementCard: React.FC<Data> = ({
    data,
    data: {
        modelNumber,
        serialNumber,
        productionDate,
        firstMessure,
        secondMessure,
        thirdMessure,
        companyId,
        qCheck,
        addedToSystem,
        clientId,
    },
    adminState: { partners },
    authState: { user },
    clientState: { clients },
}) => {
    const isDefaultCompany = JSON.parse(JSON.stringify(companyId)) !== partners[0]?._id;
    const client = clients.filter((e) => e._id === clientId)[0];

    const { clientLegalName, firstName, lastName } = client || {};

    return (
        <>
            <CardBody style={{ backgroundColor: qCheck === false ? 'rgba(255,0,0,0.5)' : 'initial' }}>
                <div>
                    <span>Модел</span> <p>{modelNumber}</p>
                </div>

                <div>
                    <span>SN:</span>
                    <p>{serialNumber}</p>
                </div>
                <div>
                    <span>1во измерване</span>
                    <p>{firstMessure}</p>
                </div>
                <div>
                    <span>2во измерване</span>
                    <p>{secondMessure}</p>
                </div>
                <div>
                    <span>3во измерване</span>
                    <p>{thirdMessure}</p>
                </div>
                <div>
                    <span>Клиент</span>
                    <p>{clientId ? (clientLegalName ? clientLegalName : firstName + ' ' + lastName) : 'На склад'}</p>
                </div>
                <div>
                    <span>Дата на производство</span>
                    <p>
                        <Moment format='DD-MM-YYYY'>{productionDate}</Moment>
                    </p>
                </div>

                {user.isAdmin && (
                    <div>
                        <p style={{ height: '100%', textAlign: 'right' }}>
                            <Link
                                onClick={(e) =>
                                    isDefaultCompany || addedToSystem || qCheck === false ? e.preventDefault() : null
                                }
                                style={{
                                    display: 'flex',
                                    height: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                to={{ pathname: '/item', state: { ...data, transfer: true } }}
                            >
                                <FontAwesomeIcon
                                    size='2x'
                                    icon={faExchangeAlt}
                                    color={isDefaultCompany || addedToSystem || qCheck === false ? 'gray' : '#e45441'}
                                />
                            </Link>
                        </p>
                    </div>
                )}

                <div>
                    <p style={{ height: '100%' }}>
                        <Link
                            style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}
                            to={{ pathname: '/itemdetails', state: data }}
                        >
                            <FontAwesomeIcon size='2x' icon={faInfoCircle} color={'#e45441'} />
                        </Link>
                    </p>
                </div>
            </CardBody>
        </>
    );
};
const mapStateToProps = (state: StateInterface) => ({
    adminState: state.adminState,
    authState: state.authState,
    clientState: state.clientState,
});
export default connect(mapStateToProps, {})(ElementCard);
