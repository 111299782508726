import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FormContainer, FormField, FormGroup, InputLable, FormSubmit, FormTextArea, Required } from '../styled/forms';
import { HeaderOne } from '../styled/common';
import { dataTrim } from '../../utils/formDataTrim';
import { createPartner, editPartner } from '../../reducers/adminActions';
const CreatePartner: React.FC<any> = ({
  location: { state },

  createPartner,
  editPartner,
}) => {
  const editData = state;
  const [id, setId] = useState('');
  const [formData, setFormData] = useState(
    editData || {
      city: '',
      legalName: ' ',
      legalAddress: '',
      EIK: ' ',
      phone: '',
      email: '',
      companyComment: '',
      repFirstName: '',
      repLastName: '',
    }
  );

  if (id) {
    return <Redirect to={{ pathname: '/partnerdetails', state: { id } }} />;
  }

  const onchangeHander = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.name === 'isAdmin' ? !!Number(e.target.value) : e.target.value,
    });
  };
  return (
    <>
      <FormContainer
        autoComplete='off'
        onSubmit={(e) => {
          e.preventDefault();
          if (editData) {
            editPartner(dataTrim(formData), setId);
          } else {
            createPartner(dataTrim(formData), setId);
          }
        }}
      >
        <HeaderOne> {editData ? 'Промяна на данните' : 'Създаване на контрагент'} </HeaderOne>

        <FormGroup>
          <InputLable>
            Наименование <Required>*</Required>
          </InputLable>
          <FormField
            name='legalName'
            onChange={onchangeHander}
            type='text'
            placeholder='Моля въведете име'
            autoComplete='off'
            value={formData.legalName}
          />
        </FormGroup>
        <FormGroup>
          <InputLable>
            EIK <Required>*</Required>
          </InputLable>
          <FormField name='EIK' type='number' onChange={onchangeHander} value={formData.EIK} autoComplete='off' />
        </FormGroup>
        <FormGroup>
          <InputLable>
            МОЛ Име <Required>*</Required>
          </InputLable>
          <FormField
            name='repFirstName'
            type='text'
            onChange={onchangeHander}
            value={formData.repFirstName}
            autoComplete='off'
          />
        </FormGroup>
        <FormGroup>
          <InputLable>
            МОЛ Фамилия <Required>*</Required>
          </InputLable>
          <FormField
            name='repLastName'
            type='text'
            onChange={onchangeHander}
            value={formData.repLastName}
            autoComplete='off'
          />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Град <Required>*</Required>
          </InputLable>
          <FormField name='city' type='text' onChange={onchangeHander} value={formData.city} autoComplete='off' />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Адрес <Required>*</Required>
          </InputLable>
          <FormField
            name='legalAddress'
            type='text'
            onChange={onchangeHander}
            value={formData.legalAddress}
            autoComplete='off'
          />
        </FormGroup>
        <FormGroup>
          <InputLable>Тел.</InputLable>
          <FormField name='phone' type='text' onChange={onchangeHander} value={formData.phone} autoComplete='off' />
        </FormGroup>
        <FormGroup>
          <InputLable>Имейл</InputLable>
          <FormField name='email' type='email' onChange={onchangeHander} value={formData.email} autoComplete='off' />
        </FormGroup>

        <FormGroup>
          <InputLable>Допълнителна информация</InputLable>
          <FormTextArea name='companyComment' onChange={onchangeHander} value={formData.companyComment} />
        </FormGroup>

        <FormSubmit>{editData ? 'Запази промените' : 'Създай контрагент'}</FormSubmit>
      </FormContainer>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  clientState: state.clientState,
  adminState: state.adminState,
});
export default connect(mapStateToProps, { createPartner, editPartner })(CreatePartner);
