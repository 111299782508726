import styled from 'styled-components';

export const MainContainer = styled.div`
    @import 'react-redux-toastr/src/styles/index';
    display: flex;

    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: center;
        background-color: ${({ theme }) => theme.light};
        max-width: 100vw;
        width: 100%;
        height: 100%;
    }
`;

export const MainContent = styled.main`
    width: 100%;
    padding-top: 0;
    margin-left: 0;
    padding: 10px;
    @media (min-width: 768px) {
        padding: 30px;
        width: calc(100% - 200px);
        padding-top: 100px;
        margin-left: auto;
        background-color: ${({ theme }) => theme.light};
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
`;
export const ContentContainer = styled.div`
    width: 100%;

    -webkit-box-shadow: -1px 1px 9px 1px rgba(0, 0, 0, 0.42);
    box-shadow: -1px 1px 9px 1px rgba(0, 0, 0, 0.42);
    height: fit-content;
    display: flex;
    flex-direction: column;

    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    margin-bottom: 10px;
    margin-top: 10px;
`;

export const ContainerRow = styled.div`
    margin-top: 20px;
    margin-bottom: 5px;
    padding: 10px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
`;
export const DetailsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 768px) {
        flex-direction: row;
        gap: 40px;
    }
    @media print {
        display: none;
    }
`;

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: 50%;
    -webkit-box-shadow: -1px 1px 9px 1px rgba(0, 0, 0, 0.42);
    box-shadow: -1px 1px 9px 1px rgba(0, 0, 0, 0.42);
    & div {
        display: flex;
        justify-content: space-between;
    }
    @media (min-width: 768px) {
        padding: 20px;
    }
`;
export const PrintContainer = styled.div`
    margin: auto;
    box-sizing: border-box;
    padding: 10px 5px;
    font-size: 14px;
    display: none;
    gap: 10px;
    height: 370px;
    width: 370px;
    flex-direction: column;
    align-items: flex-start;

    @media print {
        display: flex;
    }
`;
export const CreateInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    -webkit-box-shadow: 5px 7px 15px 2px rgba(0, 0, 0, 0.69);
    box-shadow: 5px 7px 15px 2px rgba(0, 0, 0, 0.69);
    border-radius: 5px;
    padding: 40px;
    max-width: 500px;
    width: 100%;
`;
export const AboutContainer = styled.div`
    margin-top: 50px;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & p {
        font-size: 18px;
        margin-bottom: 50px;
    }
    & img {
        width: 100%;
        height: auto;
        margin-top: 60px;
        margin-bottom: 100px;
    }
`;
export const SystemPrintContainer = styled.div`
    font-size: 20px;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    gap: 10px;
    @media print {
        display: flex;
    }
    & div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        & > span {
            display: inline-block;
            width: 30%;
        }
    }
    & h2 {
        margin-top: 40px;
    }
`;
