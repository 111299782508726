import {
  GET_ALL_CLIENTS,
  GET_SINGLE_CLIENT,
  CLIENT_LOADING,
  CREATE_CLIENT,
  EDIT_CLIENT,
  CLEAR_SINGLE_CLIENT,
  CREATE_SYSTEM,
  CREATE_ADDRESS,
  EDIT_ADDRESS,
  CREATE_ROOM,
  EDIT_ROOM,
  EDIT_SYSTEM,
  ADD_ITEM,
  REMOVE_ITEM,
  LOGOUT,
} from './types';
const initialState = {
  clients: [],
  singleClient: {},
  clientLoading: false,
};

const clinetState = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case CLIENT_LOADING:
      return { ...state, clientLoading: true };
    case CREATE_CLIENT:
      return { ...state, clients: [payload, ...state.clients], clientLoading: false };
    case EDIT_CLIENT:
      return {
        ...state,
        clients: [payload, ...state.clients.filter((e: any) => e._id !== payload._id)],
        isLoading: false,
      };
    case GET_ALL_CLIENTS:
      return { ...state, clients: payload, clientLoading: false };

    case GET_SINGLE_CLIENT:
      return { ...state, singleClient: payload, clientLoading: false };

    case CLEAR_SINGLE_CLIENT:
      return { ...state, singleClient: {} };
    case CREATE_ADDRESS:
      //@ts-ignore
      return { ...state, singleClient: { ...state.singleClient, address: [payload, ...state.singleClient.address] } };

    case EDIT_ADDRESS:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          //@ts-ignore
          address: [payload, ...state.singleClient.address.filter((e: any) => e._id !== payload._id)],
        },
      };
    case CREATE_ROOM:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          address: [
            //@ts-ignore
            ...state.singleClient.address.map((e: any) =>
              e._id !== payload.addressId ? e : { ...e, rooms: [payload, ...e.rooms] }
            ),
          ],
        },
      };
    case EDIT_ROOM:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          address: [
            //@ts-ignore
            ...state.singleClient.address.map((e: any) =>
              e._id !== payload.addressId
                ? e
                : { ...e, rooms: [payload, ...e.rooms.filter((e: any) => e._id !== payload._id)] }
            ),
          ],
        },
      };
    case CREATE_SYSTEM:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          address: [
            //@ts-ignore
            ...state.singleClient.address.map((e: any) =>
              e._id !== payload.addressId
                ? e
                : {
                    ...e,
                    rooms: e.rooms.map((e: any) =>
                      e._id !== payload.roomId ? e : { ...e, systemsInRoom: [payload, ...e.systemsInRoom] }
                    ),
                  }
            ),
          ],
        },
      };

    case EDIT_SYSTEM:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          address: [
            //@ts-ignore
            ...state.singleClient.address.map((e: any) =>
              e._id !== payload.addressId
                ? e
                : {
                    ...e,
                    rooms: e.rooms.map((e: any) =>
                      e._id !== payload.roomId
                        ? e
                        : {
                            ...e,
                            systemsInRoom: [payload, ...e.systemsInRoom.filter((e: any) => e._id !== payload._id)],
                          }
                    ),
                  }
            ),
          ],
        },
      };
    case ADD_ITEM:
    case REMOVE_ITEM:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          address: [
            //@ts-ignore
            ...state.singleClient.address.map((e: any) =>
              e._id !== payload.addressId
                ? e
                : {
                    ...e,
                    rooms: e.rooms.map((e: any) =>
                      e._id !== payload.roomId
                        ? e
                        : {
                            ...e,
                            systemsInRoom: [payload, ...e.systemsInRoom.filter((e: any) => e._id !== payload._id)],
                          }
                    ),
                  }
            ),
          ],
        },
      };
    case LOGOUT: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
export default clinetState;
