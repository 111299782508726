import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { StateInterface } from '../../interfaces/StateInterfaces';
import { SingleItem } from '../../interfaces/StateInterfaces';
import ElementCard from '../layout/ElementCard';
import { StyledPagination } from '../styled/pagination';
import ReactPaginate from 'react-paginate';
import { ContentContainer, ContainerRow } from '../styled/containers';
import { CardLables } from '../styled/card';
import { Search, ShowOnPageContainer, ShowOnPageBtn, CreateBtn, HeaderOne, Switch } from '../styled/common';
import { getAllItems } from '../../reducers/itemActions';
interface Actions {
    getAllItems?: () => void;
}

const Items: React.FC<StateInterface & Actions> = ({ getAllItems, itemState: { items }, authState: { user } }) => {
    const [showIncomplete, setShowIncomplete] = useState(false);
    const [filtered, setFiltered] = useState<SingleItem[]>(items);

    const [currentPage, setCurrentPage] = useState(Number(0));
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [pageCount, setPageCount] = useState(Math.ceil(filtered.length / itemsPerPage));
    const searchRef = useRef<HTMLInputElement>(null);
    const filterHandler = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
        let mapped: SingleItem[] = items;
        if (e !== undefined && e.target.value.length > 0 && e.target.type !== 'checkbox') {
            mapped = items.filter(
                (item) =>
                    Object.values(item)
                        .filter((v) => typeof v === 'string' || typeof v === 'number')
                        .filter((data) => {
                            return data.toString().toLowerCase().includes(e.target.value.toLowerCase());
                        }).length
            );
            setFiltered(!showIncomplete ? [...mapped] : [...mapped].filter((e) => !e.testCompleted));
            setPageCount(mapped.length / itemsPerPage);
            setCurrentPage(0);
        } else {
            //@ts-ignore
            if (showIncomplete) {
                const incomplete = [...mapped].filter((e: any) => !e.testCompleted);
                setFiltered(incomplete);
                setPageCount(incomplete.length / itemsPerPage);
            } else {
                setFiltered([...mapped]);
                setPageCount(mapped.length / itemsPerPage);
            }
        }
    };
    useEffect(() => {
        if (!items.length) {
            getAllItems && getAllItems();
        }
    }, [items, getAllItems]);
    useEffect(() => {
        setFiltered(items);
    }, [items]);

    useEffect(() => {
        filterHandler(undefined);
        if (searchRef && searchRef.current) {
            searchRef.current.value = '';
        }
    }, [showIncomplete]);

    const handleClick = (e: any) => {
        setCurrentPage(Number(e.selected));
    };

    const showOnPage = (e: React.MouseEvent<HTMLButtonElement>, num: Number) => {
        //@ts-ignore
        const elem: HTMLButtonElement = e.target;
        const elems = elem!.parentElement!.childNodes;
        elem.classList.add('active');
        Array.prototype.slice
            .call(elems)
            .filter((el) => el !== elem)
            .map((e) => e.classList.remove('active'));

        setCurrentPage(Number(0));
        setPageCount(Math.ceil(filtered.length / Number(num)));
        setItemsPerPage(Number(num));
    };

    return (
        <>
            <HeaderOne>Нагреватели</HeaderOne>
            <ContainerRow>
                <ShowOnPageContainer>
                    <ShowOnPageBtn className='active' onClick={(e) => showOnPage.call(undefined, e, 5)}>
                        5
                    </ShowOnPageBtn>
                    <ShowOnPageBtn onClick={(e) => showOnPage.call(undefined, e, 10)}>10</ShowOnPageBtn>
                    <ShowOnPageBtn onClick={(e) => showOnPage.call(undefined, e, 20)}>20</ShowOnPageBtn>
                    <ShowOnPageBtn onClick={(e) => showOnPage.call(undefined, e, 30)}>30</ShowOnPageBtn>
                    <ShowOnPageBtn onClick={(e) => showOnPage.call(undefined, e, 60)}>60</ShowOnPageBtn>
                </ShowOnPageContainer>
                <Search name='search' type='text' ref={searchRef} placeholder='Търсене...' onChange={filterHandler} />
                <label
                    htmlFor=''
                    style={{
                        height: '34px',
                        lineHeight: '34px',
                        fontFamily: "'Yanone Kaffeesatz', sans-serif",
                        fontSize: '20px',
                    }}
                >
                    <span style={{ color: '#627894' }}> Покажи само незавършени</span> {'    '}
                    <Switch>
                        <input
                            type='checkbox'
                            onChange={(e) => {
                                setShowIncomplete(!showIncomplete);
                                filterHandler(e);
                            }}
                            checked={showIncomplete}
                        />
                        <span></span>
                    </Switch>
                </label>

                {user.isAdmin && <CreateBtn to='/item'>Създай нов</CreateBtn>}
            </ContainerRow>
            <ContentContainer>
                <CardLables>
                    <p>Модел</p>
                    <p>Сериен номер</p>
                    <p>Първо измерване</p>
                    <p>Второ измерване</p>
                    <p>Трето измерване</p>
                    <p>Клиент</p>
                    <p>Дата на създаване</p>
                    {user.isAdmin && <p>Прехвърли</p>}
                    <p>Виж</p>
                </CardLables>
                {filtered.slice(itemsPerPage * currentPage, itemsPerPage * (currentPage + 1)).map((e, i) => (
                    <ElementCard key={e._id} data={e} />
                ))}
                <StyledPagination>
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={handleClick}
                        activeClassName={'active'}
                    />
                </StyledPagination>
            </ContentContainer>
        </>
    );
};
const mapStateToProps = (state: StateInterface) => ({
    itemState: state.itemState,
    authState: state.authState,
});
export default connect(mapStateToProps, { getAllItems })(Items);
