import React, { useState, useRef } from 'react';
import { faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { System, Item } from '../../interfaces/StateInterfaces';
import { CardLables } from '../styled/card';
import { CreateBtn, HeaderOne, InfoBlock, ListRow } from '../styled/common';
import { FormSubmit } from '../styled/forms';
import { DetailsContainer } from '../styled/containers';
import { addToSystem, removeFromSystem } from '../../reducers/clientActions';
import { toastr } from 'react-redux-toastr';
import SystemPrint from '../layout/SystemPrint';
import DocPrint from '../layout/DocPrint';
const SystemDetails: React.FC<any> = ({
    addToSystem,
    removeFromSystem,
    location: { state },
    clientState: { singleClient },
    authState: {
        user: {
            firstName,
            lastName,
            companyId: { legalName },
        },
    },
    itemState: { items, models },
}) => {
    const [sn, setSn] = useState('');
    const snInputRef = useRef<HTMLInputElement>(null);

    const system = state;
    if (Object.keys(singleClient).length === 0) {
        return <Redirect to='/' />;
    }
    // const evaluteInput = () => {
    //   if (snInputRef && snInputRef.current) {
    //     return snInputRef?.current.value.match(/^\d{8}$/);
    //   }
    //   return false;
    // };

    const onchangeHander = (e: React.ChangeEvent<HTMLInputElement>) => setSn(e.target.value);
    const address = singleClient.address.filter((e: any) => e._id === system.addressId)[0];
    const room = address.rooms.filter((e: any) => e._id === system.roomId)[0];
    const singleSystem: System = room.systemsInRoom.filter((e: any) => e._id === system._id)[0];

    const normalizeSn = (sn: string) => sn;

    const addItemHandler = () => {
        // if (evaluteInput()) {
        const item = items.filter((e: any) => e.serialNumber === normalizeSn(sn))[0];
        if (item) {
            if (item.testCompleted) {
                if (item.qCheck) {
                    addToSystem({ systemId: _id, serialNumber: normalizeSn(sn), clientId: singleSystem.clientId });
                    setSn('');
                } else {
                    toastr.error('Нагревателят е със статус брак!!!', '');
                }
            } else {
                toastr.error('Нагревателя е с незавършени тестове!', '');
            }
        } else {
            toastr.error('Няма нагревател с този сериен номер!', '');
        }
        // } else {
        //   toastr.error('Моля въведете 8 цифрен сериен номер', '');
        // }
    };
    const removeItemHandler = () => {
        // if (evaluteInput()) {
        removeFromSystem({ systemId: _id, serialNumber: normalizeSn(sn) });
        setSn('');
        // } else {
        //   toastr.error('Моля въведете 8 цифрен сериен номер', '');
        // }
    };
    const {
        dateCreated,
        systemComponents,
        thermoRegulatorModel,
        systemSn,
        thermoRegulatorSN,
        systemComment,
        _id,
        dateInstalled,
        totalR,
        totalI,
        surfaceTemp,
    } = singleSystem || {};
    return (
        <>
            <HeaderOne style={{ fontSize: '42px', marginBottom: '30px' }}>Система</HeaderOne>

            {singleSystem && Object.keys(singleSystem).length && (
                <DetailsContainer>
                    <InfoBlock>
                        <div className='infoblock-div' style={{ flexWrap: 'wrap', gap: '20px' }}>
                            <HeaderOne>Данни за системата</HeaderOne>
                            <CreateBtn to={{ pathname: '/system', state: singleSystem }}>Редактирай</CreateBtn>
                            {singleSystem && <SystemPrint data={{ ...singleSystem, models }} />}
                            {singleSystem && <DocPrint data={{ singleClient, singleSystem, room, address, models }} />}
                        </div>
                        <div className='infoblock-div'>
                            <span>Сериен номер система</span> <p>{systemSn}</p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Модел на регулатор</span> <p>{thermoRegulatorModel}</p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Сериен номер на регулатор</span> <p>{thermoRegulatorSN}</p>
                        </div>

                        <div className='infoblock-div'>
                            <span>Дата на инсталация</span>{' '}
                            <p>
                                {dateInstalled ? (
                                    <Moment format='DD-MM-YYYY'>{dateInstalled}</Moment>
                                ) : (
                                    'Все още не е монтирана'
                                )}
                            </p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Общо съпротивление</span> <p>{totalR + ' Ω'}</p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Номинална сила на тока</span> <p>{totalI + ' A'}</p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Повърхностна температура</span> <p>{surfaceTemp + ' °C'} </p>
                        </div>
                        {/* <div className='infoblock-div'>
                            <span>Добавен от</span>{' '}
                            <p>
                                {' '}
                                {firstName + ' ' + lastName + ' ' + legalName + ' на '}{' '}
                                <Moment format='DD.MM.YYYYг. HH.MMч.'>{dateCreated}</Moment>
                            </p>
                        </div> */}
                        <div className='infoblock-div'>
                            <span>Допълнителна информация</span> <p>{systemComment}</p>
                        </div>
                    </InfoBlock>

                    <InfoBlock>
                        <div style={{ flexWrap: 'wrap', gap: '20px' }}>
                            <HeaderOne>Нагреватели</HeaderOne>
                            <input
                                ref={snInputRef}
                                placeholder='SN на нагревател'
                                onChange={onchangeHander}
                                value={sn}
                            />{' '}
                            <FontAwesomeIcon
                                onClick={removeItemHandler}
                                style={{ fontSize: '48px', cursor: 'pointer' }}
                                icon={faTrash}
                                color={'#e45441'}
                            />
                            <FormSubmit style={{ maxWidth: '33%' }} onClick={addItemHandler}>
                                Добави нагревател
                            </FormSubmit>
                        </div>
                        {/* <div></div> */}
                        <CardLables>
                            <p>Наименование</p>
                            <p>Тип</p>
                            <p>Системи</p>
                            <p>виж</p>
                        </CardLables>

                        {systemComponents?.length ? (
                            [...systemComponents].reverse().map((e: Item) => (
                                <ListRow key={e._id}>
                                    <div>
                                        <span>Сериен номер:</span>
                                        <p>{e.serialNumber}</p>
                                    </div>
                                    <div>
                                        <span>Модел:</span>
                                        <p>{e.modelNumber}</p>
                                    </div>

                                    <div>
                                        <Link
                                            key={e._id}
                                            to={{ pathname: '/itemdetails', state: e }}
                                            style={{
                                                display: 'flex',
                                                height: '100%',
                                                width: 'fit-content',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faInfoCircle} size='2x' color={'#e45441'} />
                                        </Link>
                                    </div>
                                </ListRow>
                            ))
                        ) : (
                            <HeaderOne style={{ margin: '20px' }}>Все още няма добавени нагреватели</HeaderOne>
                        )}
                    </InfoBlock>
                </DetailsContainer>
            )}
        </>
    );
};
const mapStateToProps = (state: any) => ({
    authState: state.authState,
    clientState: state.clientState,
    itemState: state.itemState,
});
export default connect(mapStateToProps, { addToSystem, removeFromSystem })(SystemDetails);
