import React from 'react';
import { StateInterface, SinglePartner, SingleClient } from '../../interfaces/StateInterfaces';
import { connect } from 'react-redux';

import { CreateBtn, HeaderOne, InfoBlock, ListRow } from '../styled/common';
import { DetailsContainer } from '../styled/containers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { CardLables } from '../styled/card';

const PartnerDetails: React.FC<any> = ({
  location: {
    state: { id },
  },
  adminState: { partners },
  clientState: { clients },
}) => {
  const partner: SinglePartner = partners.filter((e: any) => e._id === id)[0];
  const partnerClients = clients.filter((e: any) => e.companyId === id);

  const { legalName, legalAddress, EIK, phone, email, companyComment } = partner || {};
  return (
    <>
      <HeaderOne style={{ fontSize: '42px', marginBottom: '30px' }}>КОНТРАГЕНТ</HeaderOne>
      {partner && Object.keys(partner).length && (
        <DetailsContainer>
          <InfoBlock>
            <div className='infoblock-div'>
              <HeaderOne>Данни за контрагента</HeaderOne>
              <CreateBtn to={{ pathname: '/partner', state: partner }}>Редактирай</CreateBtn>
            </div>

            <div className='infoblock-div'>
              <span>Име</span> <p>{legalName}</p>
            </div>
            <div className='infoblock-div'>
              <span>Адрес за кореспондениця </span> <p>{legalAddress}</p>
            </div>
            <div className='infoblock-div'>
              <span>EIK</span> <p>{EIK}</p>
            </div>
            <div className='infoblock-div'>
              <span>Тел.</span> <p>{phone}</p>
            </div>
            <div className='infoblock-div'>
              <span>Емейл</span> <p>{email}</p>
            </div>

            <div className='infoblock-div'>
              <span>Допълнителна информация</span> <p>{companyComment}</p>
            </div>
          </InfoBlock>

          <InfoBlock>
            <div style={{ borderBottom: partnerClients?.length ? 'none' : '1px solid black' }}>
              <HeaderOne>Клиенти</HeaderOne>
              <CreateBtn to={{ pathname: '/client', state: partner._id }}>Създай клиент</CreateBtn>
            </div>
            {partnerClients?.length ? (
              <CardLables>
                <p>Име</p>
                <p>Адрес</p>
                <p>Тел.</p>
                <p>виж</p>
              </CardLables>
            ) : null}
            {partnerClients?.length ? (
              [...partnerClients].reverse().map((e: SingleClient) => (
                <ListRow key={e._id}>
                  <div>
                    <span>Име:</span>
                    <p>{e.clientLegalName ? e.clientLegalName : e.firstName + ' ' + e.lastName}</p>
                  </div>
                  <div>
                    <span>Адрес:</span>
                    <p>{e.baseAddress}</p>
                  </div>

                  <div>
                    <Link
                      key={e._id}
                      to={{ pathname: '/clientdetails', state: { id: e._id } }}
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: 'fit-content',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} size='2x' color={'#e45441'} />
                    </Link>
                  </div>
                </ListRow>
              ))
            ) : (
              <HeaderOne style={{ margin: '20px' }}>Все още няма добавени клиенти</HeaderOne>
            )}
          </InfoBlock>
        </DetailsContainer>
      )}
    </>
  );
};

const mapStateToProps = (state: StateInterface) => ({
  clientState: state.clientState,
  authState: state.authState,
  adminState: state.adminState,
});
export default connect(mapStateToProps, {})(PartnerDetails);
