import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AuthState } from '../interfaces/StateInterfaces';
import {
  CLIENT_LOADING,
  GET_SINGLE_CLIENT,
  GET_ALL_CLIENTS,
  LOGOUT,
  CREATE_CLIENT,
  EDIT_CLIENT,
  CLEAR_SINGLE_CLIENT,
  CREATE_SYSTEM,
  CREATE_ADDRESS,
  EDIT_ADDRESS,
  CREATE_ROOM,
  EDIT_ROOM,
  EDIT_SYSTEM,
  ADD_ITEM,
  UPDATE_ITEMS,
} from './types';
import axiosInstance from '../utils/axiosInterceptor';
import { toastr } from 'react-redux-toastr';
export const getAllClients = () => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    dispatch({ type: CLIENT_LOADING });

    const res = await axiosInstance.get('/api/clients/all');

    dispatch({ type: GET_ALL_CLIENTS, payload: res.data });
  } catch (error) {
    if (error.response?.status === 401) {
      dispatch({ type: LOGOUT });
    }
    console.log(error.response);
  }
};

export const getSingleClient = (id: string) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    dispatch({ type: CLIENT_LOADING });
    const res = await axiosInstance.post('/api/clients/single', { id });
    dispatch({ type: GET_SINGLE_CLIENT, payload: res.data });
  } catch (error) {
    console.log(error);
    if (error.response === 401) {
      dispatch({ type: LOGOUT });
    }
  }
};

export const createClient = (data: any, setId: any) => async (
  dispatch: ThunkDispatch<AuthState, undefined, Action>
) => {
  try {
    dispatch({ type: CLIENT_LOADING });
    const res = await axiosInstance.post('/api/clients', data);
    dispatch({ type: CREATE_CLIENT, payload: res.data });
    toastr.success('Успешно създаден клиент!', '');
    dispatch(setId(res.data._id));
  } catch (error) {
    console.log(error);
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};

export const editClient = (data: any, setId: any) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    const res = await axiosInstance.put('/api/clients', data);
    dispatch({ type: EDIT_CLIENT, payload: res.data });
    toastr.success('Промените са записани', '');
    setId(res.data._id);
  } catch (error) {
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};
export const clearClient = () => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  dispatch({ type: CLEAR_SINGLE_CLIENT });
};

export const createAddress = (data: any, setId: any) => async (
  dispatch: ThunkDispatch<AuthState, undefined, Action>
) => {
  try {
    const res = await axiosInstance.post('/api/address', data);

    dispatch({ type: CREATE_ADDRESS, payload: res.data });
    setId(res.data);
  } catch (error) {
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};

export const editAddress = (data: any, setId: any) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    const res = await axiosInstance.put('/api/address', data);
    dispatch({ type: EDIT_ADDRESS, payload: res.data });
    setId(res.data);
  } catch (error) {
    console.log(error.response);
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};

export const createRoom = (data: any, setId: any) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    const res = await axiosInstance.post('/api/room', data);
    dispatch({ type: CREATE_ROOM, payload: res.data });
    setId(res.data);
  } catch (error) {
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};

export const editRoom = (data: any, setId: any) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    const res = await axiosInstance.put('/api/room', data);
    dispatch({ type: EDIT_ROOM, payload: res.data });
    setId(res.data);
    toastr.success('Промените са запаметени', '');
  } catch (error) {
    console.log(error.response);
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};

export const createSystem = (data: any, setId: any) => async (
  dispatch: ThunkDispatch<AuthState, undefined, Action>
) => {
  try {
    const res = await axiosInstance.post('/api/system', data);
    dispatch({ type: CREATE_SYSTEM, payload: res.data });
    setId(res.data);
    toastr.success('Системата е създадена', '');
  } catch (error) {
    console.log(error.response);
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};

export const editSystem = (data: any, setId: any) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    const res = await axiosInstance.put('/api/system', data);
    dispatch({ type: EDIT_SYSTEM, payload: res.data });
    setId(res.data);
    toastr.success('Промените са запаметени', '');
  } catch (error) {
    console.log(error.response);
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};
export const addToSystem = (data: any) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    const res = await axiosInstance.post('/api/system/additem', data);

    dispatch({ type: ADD_ITEM, payload: res.data.addedHeater });
    dispatch({ type: UPDATE_ITEMS, payload: res.data.heater });
    toastr.success('Нагревателят е добавен към системата', '');
  } catch (error) {
    console.log(error);
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};

export const removeFromSystem = (data: any) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    const res = await axiosInstance.post('/api/system/removeitem', data);

    dispatch({ type: ADD_ITEM, payload: res.data.addedHeater });
    dispatch({ type: UPDATE_ITEMS, payload: res.data.heater });
    toastr.success(`Нагревател ${res.data.heater.serialNumber} е премахнат от системата`, '');
  } catch (error) {
    console.log(error);
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};
