import React from 'react';
import { StateInterface, ItemModel } from '../../interfaces/StateInterfaces';
import { connect } from 'react-redux';

import { CreateBtn, HeaderOne, InfoBlock } from '../styled/common';
import { DetailsContainer } from '../styled/containers';
import Moment from 'react-moment';

const ModelDetails: React.FC<any> = ({
    location: {
        state: { id },
    },
    itemState: { models },
}) => {
    const model: ItemModel = models.filter((e: any) => e._id === id)[0];

    const {
        modelNumber,
        length,
        width,
        minR,
        maxR,
        nominalI,
        nominalPower,
        heatingArea,
        connectorCount,
        connectorType,
        heaterWidth,
        heaterGap,
        heaterColor,
        heaterCount,
        wireCount,
        wireType,
        dateCreated,
        modelComment,
        step,
    } = model || {};
    return (
        <>
            <HeaderOne style={{ fontSize: '42px', marginBottom: '30px' }}>МОДЕЛ</HeaderOne>
            {model && Object.keys(model).length && (
                <DetailsContainer>
                    <InfoBlock className='model-info'>
                        <div className='infoblock-div'>
                            <HeaderOne>Данни за модела</HeaderOne>
                        </div>
                        <div className='infoblock-div'>
                            <span>Модел</span> <p>{modelNumber}</p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Размери (ДхШ)</span> <p>{length + 'cm х ' + width}cm.</p>
                        </div>

                        <div className='infoblock-div'>
                            <span>Съпротивление</span> <p>{minR + 'Ω - ' + maxR + 'Ω (±10%)'}</p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Номинална мощност</span> <p>~{nominalPower}W</p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Номинален ток</span> <p>{nominalI}A</p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Нагревателна площ</span> <p>{heatingArea.toFixed(2)} кв.м.</p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Ширина на нагревателната лента</span> <p>{heaterWidth}mm</p>
                        </div>
                    </InfoBlock>
                    <InfoBlock className='model-info'>
                        <div className='infoblock-div'>
                            <CreateBtn style={{ marginLeft: 'auto' }} to={{ pathname: '/model', state: model }}>
                                Редактирай
                            </CreateBtn>
                        </div>
                        <div className='infoblock-div'>
                            <span>Стъпка</span> <p>{step}mm</p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Разтояние между нагревателни ленти</span> <p>{heaterGap}mm.</p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Връзки и проводници</span>{' '}
                            <p>
                                {connectorCount}бр - {connectorType}, {wireCount}бр - {wireType}
                            </p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Цвят на нагревател</span> <p>{heaterColor}</p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Брой нагревателни ленти</span> <p>{heaterCount}</p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Дата на създаване</span>{' '}
                            <p>
                                <Moment format='DD.MM.YYYYг. HH:MM:SSч.'>{dateCreated}</Moment>
                            </p>
                        </div>
                        <div className='infoblock-div'>
                            <span>Допълнителна информация</span> <p>{modelComment}</p>
                        </div>
                    </InfoBlock>
                </DetailsContainer>
            )}
        </>
    );
};

const mapStateToProps = (state: StateInterface) => ({
    clientState: state.clientState,
    authState: state.authState,
    adminState: state.adminState,
    itemState: state.itemState,
});
export default connect(mapStateToProps, {})(ModelDetails);
