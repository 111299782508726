import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { PrintBtn } from '../styled/common';
import { SystemPrintContainer } from '../styled/containers';
import Moment from 'react-moment';

const DocPrint: React.FC<any> = ({ data }) => {
    const [date, setDate] = useState(Date.now());
    const componentRef = useRef(null);

    const {
        room: { name, area, volume },
        singleClient: { firstName, lastName, clientLegalName, clientEIK, phone },
        singleSystem: { systemSn, thermoRegulatorModel, thermoRegulatorSN, totalR, surfaceTemp, systemComponents },
        address: { country, streetAddress, town, zipCode, district },
        models,
    } = data;

    const totalSystemPower = systemComponents
        .map((item: any) => {
            let power = 0;
            models.map((model: any) => {
                if (model.modelNumber === item.modelNumber) {
                    power = model.nominalPower;
                }
                return;
            });
            if (power) {
                return power;
            }
        })
        .reduce((a: any, b: any) => a + b, 0);
    return (
        <>
            <ReactToPrint
                trigger={() => (
                    <PrintBtn onClick={() => setDate(Date.now())}>
                        <FontAwesomeIcon icon={faPrint} /> Eксп документ
                    </PrintBtn>
                )}
                content={() => componentRef.current}
            />
            <SystemPrintContainer ref={componentRef}>
                <h2>Клиент</h2>
                <div>
                    <span>Дата: </span>
                    <Moment format='DD.MM.YYYY - HH:mm ч.' date={Date.now()} />
                </div>
                {clientLegalName && (
                    <div>
                        <span>Фирма: </span>
                        <p> {clientLegalName}</p>
                    </div>
                )}
                <div>
                    <span>ЕИК: </span>
                    <p> {clientEIK}</p>
                </div>
                <div>
                    <span>Клиент: </span>
                    <p> {firstName + ' ' + lastName}</p>
                </div>
                <div>
                    <span>Телефон: </span>
                    <p>{phone}</p>
                </div>
                <h2>Адрес</h2>
                <div>
                    <span>Държава: </span>
                    <p> {country}</p>
                </div>
                <div>
                    <span>Област: </span>
                    <p>{district}</p>
                </div>
                <div>
                    <span>Населено място:</span>
                    <p>{town}</p>
                </div>
                <div>
                    <span>Адрес:</span>
                    <p>{streetAddress}</p>
                </div>
                <div>
                    <span>Пощенски код</span>
                    <p>{zipCode}</p>
                </div>
                <h2>Помещение</h2>
                <div>
                    <span>Наименование</span>
                    <p>{name}</p>
                </div>
                <div>
                    <span>Обем:</span>
                    <p>{volume + ' m³'}</p>
                </div>
                <div>
                    <span>Площ:</span>
                    <p>{area + ' кв.м'}</p>
                </div>
                <h2>Система</h2>
                <div>
                    <span>SN:</span>
                    <p>{systemSn}</p>
                </div>
                <div>
                    <span>Модел на регулатор :</span>
                    <p>{thermoRegulatorModel}</p>
                </div>
                <div>
                    <span>SN на регулатор :</span>
                    <p>{thermoRegulatorSN}</p>
                </div>
                <div>
                    <span>Съпротивление:</span>
                    <p>{totalR + ' Ω'}</p>
                </div>
                <div>
                    <span>Топлинна Мощност:</span>
                    <p>{totalSystemPower + ' W'}</p>
                </div>
                <div>
                    <span>Повърхностна температура:</span>
                    <p>{surfaceTemp + ' °C'}</p>
                </div>
            </SystemPrintContainer>
        </>
    );
};
//systemSn,thermoRegulatorModel,thermoRegulatorSN, totalR,surfaceTemp
export default DocPrint;
