import React, { useState } from 'react';
import { SingleClient, SingleItem, StateInterface } from '../../interfaces/StateInterfaces';
import { CreateBtn, DataBtn, DataBtnContainer, TableBody } from '../styled/common';

import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';

const Data: React.FC<any> = ({ itemState: { items, models }, clientState: { clients }, adminState: { users } }) => {
    const [index, setIndex] = useState(0);
    interface ModelData {
        Размери: string;
        Модел: string;
        Мощност: string;
        'Нагревателна площ': string;
        Наличност: number;
        _id: string;
    }
    const fileNames = ['модели', 'елементи', 'клиенти'];
    const modelData: ModelData[] = models.map((e: any) => {
        const data = {} as ModelData;
        //@ts-ignore
        data['Модел'] = e.modelNumber;
        data['Размери'] = e.length + 'x' + e.width + ' cm';
        data['Мощност'] = e.nominalPower + ' W';
        data['Нагревателна площ'] = e.heatingArea.toFixed(2) + ' кв.м.';
        data['Наличност'] = items.filter((item: any) => item.modelNumber === e.modelNumber).length;
        return data;
    });

    const itemData = items.map((e: SingleItem) => {
        const data: any = {};

        const clientData = e.clientId ? clients.filter((c: any) => c._id == e.clientId)[0] : {};

        data['Модел'] = e.modelNumber;
        data['Сериен номер'] = e.serialNumber;
        data['Статус'] = e.qCheck === undefined ? 'Не завършен' : e.qCheck === true ? 'Годен' : 'Брак';
        data['Измервания'] = [e.firstMessure, e.secondMessure, e.thirdMessure].filter((e) => e !== 0).length;
        data['Вграден при клиент'] = e.clientId
            ? clientData.clientLegalName
                ? clientData.clientLegalName
                : 'test'
            : 'Не вграден';
        data['Дата на създаване'] = e.productionDate.toString().slice(0, 10).split('-').reverse().join('.');

        return data;
    });
    const clientData = clients.map((e: SingleClient) => {
        const data: any = {};
        const addedBy = users.filter((user: any) => user._id === e.addedBy)[0];
        data['Име'] = e.clientLegalName ? e.clientLegalName : e.firstName + ' ' + e.lastName;
        data['Тип'] = e.clientLegalName ? 'ЮЛ' : 'ФЗ';
        data['ЕИК'] = e.clientEIK;
        data['Седалище'] = e.baseAddress;
        data['Тел.'] = e.phone;
        data['Имейл'] = e.email;
        data['Брой обекти'] = e.address.length;
        data['Създаден от'] = addedBy.firstName + ' ' + addedBy.lastName;
        return data;
    });
    const csvData = [modelData, itemData, clientData];
    const generateModelTable = () => {
        return (
            <TableBody>
                <tr>
                    {Object.keys(modelData[0]).map((e) => (
                        <th key={e}>{e}</th>
                    ))}
                </tr>
                {modelData
                    .sort((a, b) => a['Наличност'] - b['Наличност'])
                    .map((row) => (
                        <tr key={row._id}>
                            {Object.values(row).map((prop: any, i) =>
                                i !== Object.values(row).length - 1 ? (
                                    <td key={i}>{prop}</td>
                                ) : (
                                    <td key={i} style={{ color: prop <= 5 ? 'red' : 'green' }}>
                                        {prop}
                                    </td>
                                )
                            )}
                        </tr>
                    ))}
            </TableBody>
        );
    };
    const genarateTable = (data: any) => {
        return (
            <TableBody>
                <tr>
                    {Object.keys(data[0]).map((e) => (
                        <th key={e}>{e}</th>
                    ))}
                </tr>
                {data.map((row: any, i: number) => (
                    <tr key={row._id}>
                        {Object.values(row).map((prop: any, i) => (
                            <td key={i}>{prop}</td>
                        ))}
                    </tr>
                ))}
            </TableBody>
        );
    };
    //   const dataArr = [generateModelTable, genarateTable];
    const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        const val = Number(e.currentTarget.value);
        setIndex(val);
    };
    return (
        <div style={{ width: '100%' }}>
            <DataBtnContainer>
                <DataBtn value={0} onClick={clickHandler}>
                    Наличност
                </DataBtn>
                <DataBtn value={1} onClick={clickHandler}>
                    Елементи
                </DataBtn>
                <DataBtn value={2} onClick={clickHandler}>
                    Клиенти
                </DataBtn>
                <DataBtn style={{ backgroundColor: 'green' }}>
                    <CSVLink
                        style={{ color: 'white', textDecoration: 'none' }}
                        filename={
                            'Справка ' +
                            fileNames[index] +
                            ' - ' +
                            new Date(Date.now()).toISOString().slice(0, 10).split('-').reverse().join('.') +
                            '.csv'
                        }
                        data={csvData[index]}
                    >
                        Свали справка
                    </CSVLink>
                </DataBtn>
                <CreateBtn to='/json' style={{ backgroundColor: 'green' }}>
                    Обмен на данни с други системи
                </CreateBtn>
            </DataBtnContainer>
            {index === 0 ? generateModelTable() : genarateTable(csvData[index])}
        </div>
    );
};
const mapStateToProps = (state: StateInterface) => ({
    itemState: state.itemState,
    adminState: state.adminState,
    clientState: state.clientState,
});
export default connect(mapStateToProps, {})(Data);
