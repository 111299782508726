import React from 'react';
import { connect } from 'react-redux';
import { StateInterface } from '../../interfaces/StateInterfaces';
import { SideNav, NavLink, NavLinkContainer, Logo, NavA } from '../styled/navigation';
import { Logout } from '../../interfaces/ActionInterfaces';
import { logout } from '../../reducers/authActions';
import logo from '../../assets/images/logo-itlo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUserFriends,
    faHouseUser,
    faHandshake,
    faUsersCog,
    faDatabase,
    faTemperatureHigh,
    faPencilRuler,
    faEnvelope,
    faInfo,
} from '@fortawesome/free-solid-svg-icons';
const Sidebar: React.FC<StateInterface & Logout> = ({
    authState: {
        isAuth,
        user: { isMaster, isAdmin },
        authLoading,
    },
}) => {
    return (
        <SideNav>
            <Logo src={logo} />
            <NavLinkContainer>
                {isAuth && !authLoading ? (
                    <>
                        <NavLink to='/'>
                            {' '}
                            <div className='nav-icon'>
                                <FontAwesomeIcon icon={faHouseUser} />
                            </div>
                            <span>Начало</span>
                        </NavLink>
                        <NavLink to='/items'>
                            {' '}
                            <div className='nav-icon'>
                                <FontAwesomeIcon icon={faTemperatureHigh} />
                            </div>
                            <span>Нагреватели</span>
                        </NavLink>
                        {isAdmin && (
                            <NavLink to='/models'>
                                {' '}
                                <div className='nav-icon'>
                                    <FontAwesomeIcon icon={faPencilRuler} />
                                </div>
                                <span>Модели</span>
                            </NavLink>
                        )}

                        <NavLink to='/clients'>
                            {' '}
                            <div className='nav-icon'>
                                <FontAwesomeIcon icon={faUserFriends} />
                            </div>
                            <span>Клиенти</span>
                        </NavLink>
                        {isMaster && (
                            <>
                                <NavLink to='/partners'>
                                    {' '}
                                    <div className='nav-icon'>
                                        {' '}
                                        <FontAwesomeIcon icon={faHandshake} />{' '}
                                    </div>{' '}
                                    <span>Контрагенти</span>
                                </NavLink>
                                <NavLink to='/users'>
                                    {' '}
                                    <div className='nav-icon'>
                                        {' '}
                                        <FontAwesomeIcon icon={faUsersCog} />{' '}
                                    </div>{' '}
                                    <span>Потребители</span>
                                </NavLink>
                                <NavLink to='/data'>
                                    {' '}
                                    <div className='nav-icon'>
                                        <FontAwesomeIcon icon={faDatabase} />
                                    </div>
                                    <span>Справки</span>
                                </NavLink>
                                <NavA
                                    href='mailto:itlo_2018@abv.bg
'
                                >
                                    <div className='nav-icon'>
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </div>
                                    <span> Обратна връзка</span>
                                </NavA>
                                <NavLink to='/eu'>
                                    {' '}
                                    <div className='nav-icon'>
                                        <FontAwesomeIcon icon={faInfo} />
                                    </div>
                                    <span>За платформата</span>
                                </NavLink>
                            </>
                        )}
                    </>
                ) : (
                    <NavLink to='/login'>Вход</NavLink>
                )}
            </NavLinkContainer>
        </SideNav>
    );
};
const mapStateTopProps = (state: StateInterface) => ({
    authState: state.authState,
    clientState: state.clientState,
    itemState: state.itemState,
    adminState: state.adminState,
});
export default connect(mapStateTopProps, { logout })(Sidebar);
