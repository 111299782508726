import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AuthState } from '../interfaces/StateInterfaces';
import { LOGIN_SUCCESS, AUTH_LOADING, GET_USER, LOGOUT } from './types';
import axiosInstance from '../utils/axiosInterceptor';
import { LoginData } from '../interfaces/ActionInterfaces';
import { toastr } from 'react-redux-toastr';

export const login = (data: LoginData) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    dispatch({ type: AUTH_LOADING });

    const res = await axiosInstance.post('/api/auth/login', data);
    dispatch({ type: LOGIN_SUCCESS, payload: res.data });
    toastr.success('Влязохте Успешно!', '');
    return dispatch(getUser(res));
  } catch (error) {
    if (error.response.status >= 500) {
      toastr.error('Сървърна грешка, моля опитайте отново след няколко минути', '');
    }
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};

export const getUser = (_: any) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    const user = await axiosInstance.get('/api/auth');

    dispatch({ type: GET_USER, payload: user.data });
  } catch (error) {
    if (error.response.status >= 500) {
      toastr.error('Сървърна грешка, моля опитайте отново след няколко минути', '');
    }
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};
export const logout = () => (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  toastr.success('Упсешно Излизане', '', { timeOut: 1500 });
  return dispatch({ type: LOGOUT });
};

export const forgotPassword = (email: string) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    const code = await axiosInstance.post('/api/auth/forgotpassword', { email });

    if (code.status === 201) {
      toastr.success('Изпратен е мейл за смана на парола.', '', { timeOut: 4000 });
    }
  } catch (error) {
    if (error.response.status >= 500) {
      toastr.error('Сървърна грешка, моля опитайте отново след няколко минути', '');
    }
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};
export const changePassword = (data: object, history: any) => async (
  dispatch: ThunkDispatch<AuthState, undefined, Action>
) => {
  try {
    const code = await axiosInstance.post('/api/auth/changepassword', data);
    if (code.status === 201) {
      history.push('/login');
      toastr.success('Успешно сменихте Вашата парола!', '');
    }
  } catch (error) {
    if (error.response.status >= 500) {
      toastr.error('Сървърна грешка, моля опитайте отново след няколко минути', '');
    }
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};
