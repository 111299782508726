import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { ItemModel, StateInterface } from '../../interfaces/StateInterfaces';

import { StyledPagination } from '../styled/pagination';
import ReactPaginate from 'react-paginate';
import { ContentContainer, ContainerRow } from '../styled/containers';
import { CardLables } from '../styled/card';
import { Search, ShowOnPageContainer, ShowOnPageBtn, CreateBtn, HeaderOne } from '../styled/common';
import { getAllItems } from '../../reducers/itemActions';
import ModelCard from '../layout/ModelCard';

const Models: React.FC<StateInterface> = ({ itemState: { models }, authState: { user } }) => {
  const [filtered, setFiltered] = useState<ItemModel[]>(models);

  const [currentPage, setCurrentPage] = useState(Number(0));
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(Math.ceil(filtered.length / itemsPerPage));
  const searchRef = useRef<HTMLInputElement>(null);
  const filterHandler = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    let mapped: ItemModel[] = models;
    if (e !== undefined && e.target.value.length > 0) {
      mapped = models.filter(
        (item) =>
          Object.values(item)
            .filter((v) => typeof v === 'string' || typeof v === 'number')
            .filter((data) => {
              return data.toString().toLowerCase().includes(e.target.value.toLowerCase());
            }).length
      );
      setPageCount(mapped.length / itemsPerPage);
      setCurrentPage(0);
      setFiltered([...mapped]);
    }
  };

  const handleClick = (e: any) => {
    setCurrentPage(Number(e.selected));
  };

  const showOnPage = (e: React.MouseEvent<HTMLButtonElement>, num: Number) => {
    //@ts-ignore
    const elem: HTMLButtonElement = e.target;
    const elems = elem!.parentElement!.childNodes;
    elem.classList.add('active');
    Array.prototype.slice
      .call(elems)
      .filter((el) => el !== elem)
      .map((e) => e.classList.remove('active'));

    setCurrentPage(Number(0));
    setPageCount(Math.ceil(filtered.length / Number(num)));
    setItemsPerPage(Number(num));
  };

  return (
    <>
      <HeaderOne>Модели</HeaderOne>
      <ContainerRow>
        <ShowOnPageContainer>
          <ShowOnPageBtn className='active' onClick={(e) => showOnPage.call(undefined, e, 5)}>
            5
          </ShowOnPageBtn>
          <ShowOnPageBtn onClick={(e) => showOnPage.call(undefined, e, 10)}>10</ShowOnPageBtn>
          <ShowOnPageBtn onClick={(e) => showOnPage.call(undefined, e, 20)}>20</ShowOnPageBtn>
          <ShowOnPageBtn onClick={(e) => showOnPage.call(undefined, e, 30)}>30</ShowOnPageBtn>
          <ShowOnPageBtn onClick={(e) => showOnPage.call(undefined, e, 60)}>60</ShowOnPageBtn>
        </ShowOnPageContainer>
        <Search name='search' type='text' ref={searchRef} placeholder='Търсене...' onChange={filterHandler} />

        {user.isMaster && <CreateBtn to={{ pathname: '/model' }}>Добави модел</CreateBtn>}
      </ContainerRow>
      <ContentContainer>
        <CardLables>
          <p>Модел</p>
          <p>Съпротивление</p>
          <p>Размери</p>
          <p>Мощност</p>
          <p>Дата на създаване</p>

          <p>Виж</p>
        </CardLables>
        {filtered.slice(itemsPerPage * currentPage, itemsPerPage * (currentPage + 1)).map((e, i) => (
          <ModelCard key={e._id} data={e} />
        ))}
        <StyledPagination>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handleClick}
            activeClassName={'active'}
          />
        </StyledPagination>
      </ContentContainer>
    </>
  );
};
const mapStateToProps = (state: StateInterface) => ({
  itemState: state.itemState,
  authState: state.authState,
});
export default connect(mapStateToProps, { getAllItems })(Models);
