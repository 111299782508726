import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import HomeComponent from './components/pages/Home';
import LoginComponent from './components/pages/Login';
import ClientDetails from './components/pages/ClientDetails';
import AdressDetails from './components/pages/AddessDetails';
import Roomdetails from './components/pages/RoomDetails';
import SystemDetails from './components/pages/SystemDetails';
import ChangePassword from './components/pages/ChangePassword';
import AuthChecker from './components/utils/AuthChecker';
import { MainContainer, MainContent } from './components/styled/containers';
import MainNav from './components/layout/MainNav';
import Sidebar from './components/layout/Sidebar';
import { theme } from './components/styled/theme';
import PrivateRoute from './components/routing/PrivateRoute';

import Clients from './components/pages/Clients';
import Items from './components/pages/Items';
import CreateClient from './components/pages/CreateClient';
import CreateAddress from './components/pages/CreateAddress';
import CreateRoom from './components/pages/CreateRoom';
import CreateSystem from './components/pages/CreateSystem';
import ItemDetails from './components/pages/ItemDetails';
import CreateItem from './components/pages/CreateItem';
import Partners from './components/pages/Partners';
import PartnerDetails from './components/pages/PartnerDetails';
import CreateModel from './components/pages/CreateModel';
import CreateUser from './components/pages/CreateUser';
import { handleCrumb, clearCrumb } from './reducers/adminActions';
import BreadCrumb from './components/layout/BreadCrumb';
import Users from './components/pages/Users';
import UserDetails from './components/pages/UserDetails';
import Models from './components/pages/Models';
import ModelDetails from './components/pages/ModelDetails';
import Confirmed from './components/pages/Confirmed';
import CreatePartner from './components/pages/CreatePartner';
import Data from './components/pages/Data';
import JsonData from './components/pages/JsonData';
import About from './components/pages/About';
//@ts-ignore
const App: React.FC = ({ history, handleCrumb, clearCrumb }) => {
    useEffect(() => {
        history.listen((e: any) => {
            const clearRoutes = ['/', '/items', '/clients', '/partners', '/users', '/data', '/eu'].includes(e.pathname);
            if (clearRoutes) {
                clearCrumb(e);
            } else {
                handleCrumb(e);
            }
        });
    }, [history, clearCrumb, handleCrumb]);
    return (
        <>
            <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position='top-right'
                transitionIn='fadeIn'
                transitionOut='fadeOut'
                progressBar
                closeOnToastrClick
            />
            <AuthChecker />
            <ThemeProvider theme={theme}>
                <MainNav />
                <MainContainer>
                    <Sidebar />
                    <MainContent>
                        <BreadCrumb />
                        <Switch>
                            <Route exact path='/login' component={LoginComponent} />
                            <Route path='/forgotpassword/:token' component={ChangePassword} />
                            <Route exact path='/confirmed' component={Confirmed} />
                            <PrivateRoute exact path='/' component={HomeComponent} />
                            <PrivateRoute exact path='/home' component={HomeComponent} />
                            <PrivateRoute exact path='/partners' component={Partners} />
                            <PrivateRoute exact path='/clientdetails' component={ClientDetails} />
                            <PrivateRoute exact path='/itemdetails' component={ItemDetails} />
                            <PrivateRoute exact path='/addressdetails' component={AdressDetails} />
                            <PrivateRoute exact path='/roomdetails' component={Roomdetails} />
                            <PrivateRoute exact path='/systemdetails' component={SystemDetails} />
                            <PrivateRoute exact path='/partnerdetails' component={PartnerDetails} />
                            <PrivateRoute exact path='/clients' component={Clients} />
                            <PrivateRoute exact path='/items' component={Items} />
                            <PrivateRoute exact path='/client' component={CreateClient} />
                            <PrivateRoute exact path='/address' component={CreateAddress} />
                            <PrivateRoute exact path='/room' component={CreateRoom} />
                            <PrivateRoute exact path='/system' component={CreateSystem} />
                            <PrivateRoute exact path='/item' component={CreateItem} />
                            <PrivateRoute exact path='/model' component={CreateModel} />
                            <PrivateRoute exact path='/user' component={CreateUser} />
                            <PrivateRoute exact path='/users' component={Users} />
                            <PrivateRoute exact path='/userdetails' component={UserDetails} />
                            <PrivateRoute exact path='/models' component={Models} />
                            <PrivateRoute exact path='/ModelDetails' component={ModelDetails} />
                            <PrivateRoute exact path='/partner' component={CreatePartner} />
                            <PrivateRoute exact path='/data' component={Data} />
                            <PrivateRoute exact path='/json' component={JsonData} />
                            <Route path='/eu' component={About} />
                        </Switch>
                    </MainContent>
                </MainContainer>
            </ThemeProvider>
        </>
    );
};

export default connect(undefined, { handleCrumb, clearCrumb })(withRouter(App));
