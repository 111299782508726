import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdminState, SingleClient, StateInterface } from '../../interfaces/StateInterfaces';
import { CardBody } from '../styled/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
interface Data {
  data: SingleClient;
}
interface State {
  adminState?: AdminState;
}
const ClientCard: React.FC<Data & State> = ({
  data: { _id, firstName, lastName, email, phone, clientLegalName, dateCreated, companyId },
  adminState,
}) => {
  const partners = adminState?.partners;

  const partner = partners?.filter((e: any) => e._id === companyId)[0];

  return (
    <>
      <CardBody>
        {clientLegalName ? (
          <div>
            <span>Фирма</span> <p>{clientLegalName}</p>
          </div>
        ) : (
          <div>
            <span>Име</span>
            <p>{firstName + ' ' + lastName}</p>
          </div>
        )}
        <div>
          <span>Тел.</span>
          <p>{phone}</p>
        </div>
        <div>
          <span>Имейл</span>
          <p>{email}</p>
        </div>
        <div>
          <span>Контрагент</span>
          <p>{partner?.legalName}</p>
        </div>
        <div>
          <span>Създаден</span>
          <p>
            <Moment format='DD-MM-YYYY'>{dateCreated}</Moment>
          </p>
        </div>

        <div>
          <p style={{ height: '100%' }}>
            <Link
              style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}
              to={{ pathname: '/clientdetails', state: { id: _id } }}
            >
              <FontAwesomeIcon size='2x' icon={faInfoCircle} color={'#e45441'} />
            </Link>
          </p>
        </div>
      </CardBody>
    </>
  );
};
const mapStateToProps = (state: StateInterface) => ({
  adminState: state.adminState,
});
export default connect(mapStateToProps, {})(ClientCard);
