import React from 'react';
import loader from '../assets/images/loader.gif';
const Loading = () => {
  return (
    <div style={{ width: '100', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src={loader} alt='loader' style={{ width: '350px', height: '350px' }} />
    </div>
  );
};

export default Loading;
