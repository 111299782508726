import React from 'react';
import { Redirect } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

const Confirmed = () => {
  toastr.success('Имейлът е потвърден успешно!', '');
  return <Redirect to={{ pathname: '/' }} />;
};

export default Confirmed;
