import styled from 'styled-components';

export const CardBody = styled.div`
  flex-direction: column;
  display: flex;
  gap: 10px;
  padding: 10px 15px;
  font-weight: 400;
  width: 100%;
  font-size: 12px;

  justify-content: space-between;
  align-items: center;

  & span {
    display: block;
    width: 50%;
  }

  & > div {
    display: flex;
    flex-direction: row;
    width: 100%;

    justify-content: flex-start;
    & > p {
      width: 60%;
    }

    &:last-child {
      & p {
        margin-left: auto;
        width: fit-content;
      }
    }
  }
  @media (max-width: 767.5px) {
    & > div {
    }
    &:nth-child(odd) {
      & > div {
        &:not(:last-child) {
          padding-bottom: 10px;
          border-bottom: 1px solid white;
        }
      }
      background-color: #ebecf0;
    }

    &:nth-child(even) {
      & > div {
        &:not(:last-child) {
          padding-bottom: 10px;
          border-bottom: 1px solid #ebecf0;
        }
        background-color: white;
      }
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    font-size: 14px;

    &:nth-child(odd) {
      background-color: white;
    }
    &:nth-child(even) {
      background-color: #ebecf0;
    }
    & span {
      display: none;
    }
    & {
      display: flex;
      justify-content: space-between;
      & > p {
        width: 20%;
        &:last-child {
          text-align: right;
          padding-right: 5px;
        }
      }
    }
  }
`;
export const Label = styled.span`
  color: gray;
`;
export const ViewBtn = styled.button`
  max-width: 100%;
  padding: 12px 24px;

  cursor: pointer;
`;
export const CardLables = styled(CardBody)`
  display: none !important;
  @media (min-width: 768px) {
    display: flex !important;

    padding: 15px;

    background-color: white !important;
  }
`;
