import {
    GET_USERS,
    GET_COMPANIES,
    LOGOUT,
    HANDLE_CRUMB,
    CLEAR_CRUMB,
    CREATE_USER,
    CREATE_PARTNER,
    EDIT_PARTNER,
} from './types';

const initialState = {
    partners: [],
    users: [],
    breadCrumb: [{ pathname: '/' }],
};

const authState = (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case GET_USERS:
            return { ...state, users: payload };
        case GET_COMPANIES:
            return { ...state, partners: payload };
        case LOGOUT: {
            return { ...initialState };
        }
        case HANDLE_CRUMB:
            //@ts-ignore

            return {
                ...state,
                breadCrumb:
                    state.breadCrumb.filter((e: any) => e.pathname === payload.pathname).length === 0
                        ? [...state.breadCrumb, payload]
                        : state.breadCrumb.slice(
                              0,
                              state.breadCrumb.findIndex((elem: any) => elem.pathname === payload.pathname) + 1
                          ),
            };
        case CLEAR_CRUMB:
            return { ...state, breadCrumb: payload };
        case CREATE_USER:
            return { ...state, users: [payload, ...state.users] };

        case CREATE_PARTNER:
        case EDIT_PARTNER:
            return { ...state, partners: [payload, ...state.partners.filter((e: any) => e._id !== payload._id)] };
        default:
            return state;
    }
};
export default authState;
