import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    FormContainer,
    FormField,
    FormGroup,
    InputLable,
    FormSubmit,
    FormTextArea,
    Required,
    SelectField,
} from '../styled/forms';
import { createItem, editItem } from '../../reducers/itemActions';
import { HeaderOne } from '../styled/common';
import { toastr } from 'react-redux-toastr';
import { dataTrim } from '../../utils/formDataTrim';
import { Item } from '../../interfaces/StateInterfaces';
// import ModelDetailsBox from '../layout/ModelDetailsBox';

const CreateItem: React.FC<any> = ({
    location: { state },
    itemState: { models, items },
    adminState: { partners },
    authState: { user },
    createItem,
    editItem,
}) => {
    const editData = state;
    const [id, setId] = useState('');
    const sn =
        Number(
            items
                .sort((a: Item, b: Item) => Number(a.serialNumber.slice(4)) - Number(b.serialNumber.slice(4)))
                .reverse()[0].serialNumber
        ) + 1;
    const itloData = partners.filter((e: any) => e.EIK === 205279456)[0];
    const [formData, setFormData] = useState(
        editData || {
            modelNumber: '',
            firstMessure: 0,
            secondMessure: 0,
            thirdMessure: 0,
            itemComment: '',
            serialNumber: sn,
            companyId: itloData._id,
            createdBy: user._id,
            deliveryDate: '',
        }
    );
    const selectedModel = models.filter((model: any) => model.modelNumber === formData.modelNumber)[0] || {};
    const { minR, maxR, nominalI, nominalPower } = selectedModel;
    const partnersData = partners.filter((e: any) => e.EIK !== 205279456);
    if (id) {
        return <Redirect to={{ pathname: '/itemdetails', state: id }} />;
    }
    const onchangeHander = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    return (
        <>
            <FormContainer
                onSubmit={(e) => {
                    e.preventDefault();
                    if (!formData.modelNumber) {
                        return toastr.error('Моля въведете модел!', '');
                    }
                    if (!editData) {
                        createItem(dataTrim(formData), setId);
                    } else {
                        editItem(dataTrim(formData), setId);
                    }
                }}
            >
                <HeaderOne>
                    {' '}
                    {editData
                        ? editData.transfer
                            ? 'Прехвърли към контрагент'
                            : 'Мониторинг на процеса на производство'
                        : 'Въвеждане на данни за материал'}{' '}
                </HeaderOne>
                <FormGroup>
                    <InputLable>Модел</InputLable>

                    <SelectField
                        name='modelNumber'
                        onChange={onchangeHander}
                        value={formData.modelNumber}
                        disabled={editData}
                    >
                        <option value=''>Моля въведете модел!</option>
                        {models.map((e: any) => (
                            <option key={e._id} value={e.modelNumber}>
                                {e.modelNumber}
                            </option>
                        ))}
                    </SelectField>
                </FormGroup>
                <FormGroup>
                    <InputLable>Партньор</InputLable>

                    <SelectField
                        autoComplete='off'
                        name='companyId'
                        onChange={onchangeHander}
                        value={formData.companyId}
                    >
                        <option value={itloData._id}>{itloData.legalName} </option>
                        {partnersData.map((e: any) => (
                            <option key={e._id} value={e._id}>
                                {e.legalName}
                            </option>
                        ))}
                    </SelectField>
                </FormGroup>
                <FormGroup>
                    <InputLable>
                        Производствен и сериен номер <Required>*</Required>
                    </InputLable>
                    <FormField
                        autoComplete='off'
                        name='serialNumber'
                        onChange={onchangeHander}
                        value={formData.serialNumber}
                        disabled={true}
                    />
                </FormGroup>
                <FormGroup>
                    <InputLable>
                        Напрежение <Required>*</Required>
                    </InputLable>
                    <FormField value={'230 V'} disabled={true} />
                </FormGroup>
                <FormGroup>
                    <InputLable>
                        Топлинна мощност <Required>*</Required>
                    </InputLable>
                    <FormField value={nominalPower ? nominalPower + ' W' : ''} disabled={true} />
                </FormGroup>
                <FormGroup>
                    <InputLable>
                        Максимана сила на тока <Required>*</Required>
                    </InputLable>
                    <FormField value={nominalI ? nominalI + ' А' : ''} disabled={true} />
                </FormGroup>
                <FormGroup>
                    <InputLable>
                        Граници на съпротивление <Required>*</Required>
                    </InputLable>
                    <FormField value={minR ? minR + ' Ω - ' + maxR + ' Ω' : ''} disabled={true} />
                </FormGroup>
                <FormGroup>
                    <InputLable>
                        Първо измерване (съпротивление) <Required>*</Required>
                    </InputLable>
                    <FormField
                        autoComplete='off'
                        name='firstMessure'
                        onChange={onchangeHander}
                        value={formData.firstMessure}
                        disabled={editData?.firstMessure || editData?.transfer}
                    />
                    <small>
                        При незавършено замерване, въведете 0. След въвеждане на различна стойност, не е възможна
                        промяна!
                    </small>
                </FormGroup>
                {editData && (
                    <FormGroup>
                        <InputLable>
                            Второ измерване (съпротивление) <Required>*</Required>
                        </InputLable>
                        <FormField
                            name='secondMessure'
                            onChange={onchangeHander}
                            value={formData.secondMessure}
                            disabled={editData?.secondMessure || editData?.transfer}
                        />
                        <small>
                            При незавършено замерване, въведете 0. След въвеждане на различна стойност, не е възможна
                            промяна!
                        </small>
                    </FormGroup>
                )}
                {editData && (
                    <FormGroup>
                        <InputLable>
                            Трето измерване (съпротивление) <Required>*</Required>
                        </InputLable>
                        <FormField
                            name='thirdMessure'
                            onChange={onchangeHander}
                            value={formData.thirdMessure}
                            disabled={editData?.thirdMessure || editData?.transfer}
                        />
                        <small>
                            При незавършено замерване, въведете 0. След въвеждане на различна стойност, не е възможна
                            промяна!
                        </small>
                    </FormGroup>
                )}

                <FormGroup>
                    <InputLable>Дата на зачисляване</InputLable>
                    <input
                        name='deliveryDate'
                        onChange={onchangeHander}
                        value={formData.deliveryDate ? formData.deliveryDate.slice(0, 10) : ''}
                        // disabled={editData?.transfer}
                        type='date'
                        style={{ padding: '10px' }}
                    />
                </FormGroup>

                <FormGroup>
                    <InputLable>Допълнителна информация</InputLable>
                    <FormTextArea
                        name='itemComment'
                        onChange={onchangeHander}
                        value={formData.itemComment}
                        disabled={editData?.transfer}
                    />
                </FormGroup>

                <FormSubmit>{editData ? 'Производство' : 'Създай нагревател'}</FormSubmit>
            </FormContainer>
        </>
    );
};
const mapStateToProps = (state: any) => ({
    clientState: state.clientState,
    itemState: state.itemState,
    adminState: state.adminState,
    authState: state.authState,
});
export default connect(mapStateToProps, { createItem, editItem })(CreateItem);
