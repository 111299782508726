import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FormContainer, FormField, FormGroup, InputLable, FormSubmit, ForgotPassBtn } from '../styled/forms';
import { login, forgotPassword } from '../../reducers/authActions';
import { StateInterface } from '../../interfaces/StateInterfaces';
import { ActionsInterface, ForgotPassword } from '../../interfaces/ActionInterfaces';
import { HeaderOne } from '../styled/common';
const Login: React.FC<StateInterface & ActionsInterface & ForgotPassword> = ({
  login,
  forgotPassword,
  authState: {
    isAuth,
    user: { _id },
  },
}) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    emailForgot: '',
  });
  const [isHidden, setIsHidden] = useState(true);

  if (isAuth && _id) {
    return <Redirect to='/' />;
  }
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.currentTarget.name]: e.target.value });
  };

  const test = (e: React.FormEvent) => {
    e.preventDefault();
    login(formData);
  };
  const showHideForgotenPass = (e: React.MouseEvent) => setIsHidden(!isHidden);
  return (
    <>
      <HeaderOne>Вход</HeaderOne>
      <FormContainer onSubmit={test}>
        <FormGroup>
          <FormField type='email' name='email' onChange={onChangeHandler} value={formData.email} />
          <InputLable htmlFor='email'>Емейл</InputLable>
        </FormGroup>
        <FormGroup>
          <FormField type='password' name='password' onChange={onChangeHandler} value={formData.password} />
          <InputLable htmlFor='password'>Парола</InputLable>
        </FormGroup>
        <FormGroup>
          <FormSubmit type='submit'>Вход</FormSubmit>
        </FormGroup>
        <FormGroup>
          <ForgotPassBtn type='reset' onClick={showHideForgotenPass}>
            Забравена парола?
          </ForgotPassBtn>
        </FormGroup>
        <FormGroup style={{ display: isHidden ? 'none' : 'block' }}>
          <FormField
            type='test'
            name='emailForgot'
            onChange={onChangeHandler}
            value={formData.emailForgot}
            placeholder='Email...'
          />
          <InputLable htmlFor='emailForgot'>Посочете имейл за промяна на парола</InputLable>
          <FormSubmit
            type='button'
            style={{ marginTop: '30px' }}
            onClick={forgotPassword.bind(undefined, formData.emailForgot)}
          >
            Смени Парола
          </FormSubmit>
        </FormGroup>
      </FormContainer>
    </>
  );
};
const mapStateTopProps = (state: StateInterface) => ({
  authState: state.authState,
});
export default connect(mapStateTopProps, { login, forgotPassword })(Login);
