import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ItemState } from '../interfaces/StateInterfaces';
import { ITEM_LOADING, GET_ITEMS, LOGOUT, GET_MODELS, CREATE_ITEM, EDIT_ITEM, EDIT_MODEL, CREATE_MODEL } from './types';
import axiosInstance from '../utils/axiosInterceptor';
import { toastr } from 'react-redux-toastr';

export const createItem = (data: any, setId: any) => async (dispatch: ThunkDispatch<ItemState, undefined, Action>) => {
    try {
        const res = await axiosInstance.post('/api/items', data);
        dispatch({ type: CREATE_ITEM, payload: res.data });
        setId(res.data);
        toastr.success('Нагревателят е създаден', '');
    } catch (error) {
        if (error.response.status === 401) {
            dispatch({ type: LOGOUT });
        }
        console.log(error.response);
        error?.response?.data?.map((e: any) => toastr.error(e.msg, ''));
    }
};

export const editItem = (data: any, setId: any) => async (dispatch: ThunkDispatch<ItemState, undefined, Action>) => {
    try {
        const res = await axiosInstance.put('/api/items', data);
        dispatch({ type: EDIT_ITEM, payload: res.data });
        toastr.success('Промените са запаемтени', '');
        console.log(res.data);
        setId(res.data);
    } catch (error) {
        console.log(error.response);
        error?.response?.data?.map((e: any) => toastr.error(e.msg, ''));
    }
};
export const getAllItems = () => async (dispatch: ThunkDispatch<ItemState, undefined, Action>) => {
    try {
        dispatch({ type: ITEM_LOADING });

        const res = await axiosInstance.get('/api/items');
        dispatch({ type: GET_ITEMS, payload: res.data });
    } catch (error) {
        if (error.response.status === 401) {
            dispatch({ type: LOGOUT });
        }
        console.log(error.response);
        error?.response?.data?.map((e: any) => toastr.error(e.msg, ''));
    }
};

export const getModels = () => async (dispatch: ThunkDispatch<ItemState, undefined, Action>) => {
    try {
        const res = await axiosInstance.get('/api/model');
        dispatch({ type: GET_MODELS, payload: res.data });
    } catch (error) {
        if (error.response.status === 401) {
            dispatch({ type: LOGOUT });
        }
        console.log(error.response);
        error?.response?.data?.map((e: any) => toastr.error(e.msg, ''));
    }
};

export const createModel = (data: any, setId: any) => async (dispatch: ThunkDispatch<ItemState, undefined, Action>) => {
    try {
        const res = await axiosInstance.post('/api/model', data);
        dispatch({ type: CREATE_MODEL, paylod: res.data });
        dispatch(setId(res.data._id));
        toastr.success('Моделът е успешно създаден', '');
    } catch (error) {
        if (error?.response?.status >= 500) {
            toastr.error('Сървърна грешка, моля опитайте отново след няколко минути', '');
        }
        error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
    }
};

export const editModel = (data: any, setId: any) => async (dispatch: ThunkDispatch<ItemState, undefined, Action>) => {
    try {
        const res = await axiosInstance.put('/api/model', data);
        dispatch({ type: EDIT_MODEL, payload: res.data });

        setId(res.data._id);
        toastr.success('Промените са записани', '');
    } catch (error) {
        console.log(error);
        if (error?.response?.status >= 500) {
            toastr.error('Сървърна грешка, моля опитайте отново след няколко минути', '');
        }
        error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
    }
};
