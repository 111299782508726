import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SinglePartner } from '../../interfaces/StateInterfaces';
import { GetSingleClient } from '../../interfaces/ActionInterfaces';
import { CardBody } from '../styled/card';
import { getSingleClient } from '../../reducers/clientActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
interface Data {
  data: SinglePartner;
}
const PartnerCard: React.FC<Data & GetSingleClient> = ({
  data: { _id, dateCreated, legalName, legalAddress, email, phone, city },
  getSingleClient,
}) => {
  return (
    <>
      <CardBody>
        <div>
          <span>Име</span> <p>{legalName}</p>
        </div>
        <div>
          <span>Град</span> <p>{city}</p>
        </div>

        <div>
          <span>Адрес</span> <p>{legalAddress}</p>
        </div>

        <div>
          <span>Емейл</span>
          <p>{email}</p>
        </div>
        <div>
          <span>Тел.</span>
          <p>{phone}</p>
        </div>
        <div>
          <span>Създаден</span>
          <p>
            <Moment format='DD-MM-YYYY'>{dateCreated}</Moment>
          </p>
        </div>

        <div>
          <p style={{ height: '100%' }}>
            <Link
              style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}
              to={{ pathname: '/partnerdetails', state: { id: _id } }}
            >
              <FontAwesomeIcon size='2x' icon={faInfoCircle} color={'#e45441'} />
            </Link>
          </p>
        </div>
      </CardBody>
    </>
  );
};

export default connect(undefined, { getSingleClient })(PartnerCard);
