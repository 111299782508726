import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Address, Room } from '../../interfaces/StateInterfaces';
import { CreateBtn, HeaderOne, InfoBlock, ListRow } from '../styled/common';
import { DetailsContainer } from '../styled/containers';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { CardLables } from '../styled/card';

import Loading from '../Loading';
const AddessDetails: React.FC<any> = ({
    location: { state },
    clientState: {
        clientLoading,
        singleClient: { address },
        singleClient,
    },
    authState: {
        user: {
            firstName,
            lastName,
            companyId: { legalName },
        },
    },
}) => {
    if (Object.keys(singleClient).length === 0) {
        return <Redirect to='/' />;
    }
    const singleAddress: Address = address.filter((e: any) => e._id === state._id)[0];
    const { _id, country, district, streetAddress, town, zipCode, dateCreated, addressComment, addedBy } =
        singleAddress;

    if (!clientLoading) {
        return (
            <>
                <HeaderOne style={{ fontSize: '42px', marginBottom: '30px' }}> АДРЕС</HeaderOne>

                {Object.keys(singleAddress).length && (
                    <DetailsContainer>
                        <InfoBlock>
                            <div className='infoblock-div'>
                                <HeaderOne>Данни за адреса</HeaderOne>

                                <CreateBtn
                                    style={{ marginLeft: '20px' }}
                                    to={{ pathname: '/address', state: singleAddress }}
                                >
                                    Редактирай
                                </CreateBtn>
                            </div>

                            <div className='infoblock-div'>
                                <span>Държава</span> <p>{country}</p>
                            </div>
                            <div className='infoblock-div'>
                                <span>Град</span> <p>{town}</p>
                            </div>
                            <div className='infoblock-div'>
                                <span>Община</span> <p>{district}</p>
                            </div>
                            <div className='infoblock-div'>
                                <span>Пощенски код</span> <p>{zipCode}</p>
                            </div>
                            <div className='infoblock-div'>
                                <span>Адрес</span> <p>{streetAddress}</p>
                            </div>
                            <div className='infoblock-div'>
                                <span>Добавен от</span>{' '}
                                <p>
                                    {' '}
                                    {addedBy.firstName +
                                        ' ' +
                                        addedBy.lastName +
                                        ' ' +
                                        addedBy.companyId.legalName +
                                        ' на '}{' '}
                                    <Moment format='DD-MM-YYYYг.'>{dateCreated}</Moment>
                                </p>
                            </div>
                            <div className='infoblock-div'>
                                <span>Допълнителна информация</span> <p>{addressComment}</p>
                            </div>
                        </InfoBlock>

                        <InfoBlock>
                            <div>
                                <HeaderOne>Помещения</HeaderOne>
                                <CreateBtn to={{ pathname: '/room', state: { addressId: _id } }}>
                                    Създай помещение
                                </CreateBtn>
                            </div>

                            <CardLables>
                                <p>Наименование</p>
                                <p>Тип</p>
                                <p>Системи</p>
                                <p>виж</p>
                            </CardLables>

                            {singleAddress.rooms?.length ? (
                                singleAddress.rooms?.map((e: Room) => (
                                    <ListRow key={e._id}>
                                        <div>
                                            <span>Наименование:</span>
                                            <p>{e.name}</p>
                                        </div>
                                        <div>
                                            <span>Тип:</span>
                                            <p>{e.roomType}</p>
                                        </div>
                                        <div>
                                            <span>Системи:</span>
                                            <p>{e.systemsInRoom.length}</p>
                                        </div>
                                        <div>
                                            <Link
                                                key={e._id}
                                                to={{ pathname: '/roomdetails', state: e }}
                                                style={{
                                                    display: 'flex',
                                                    height: '100%',
                                                    width: 'fit-content',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faInfoCircle} size='2x' color={'#e45441'} />
                                            </Link>
                                        </div>
                                    </ListRow>
                                ))
                            ) : (
                                <HeaderOne style={{ margin: '20px' }}>Все още няма добавени помещения</HeaderOne>
                            )}
                        </InfoBlock>
                    </DetailsContainer>
                )}
            </>
        );
    } else {
        return <Loading />;
    }
};
const mapStateToProps = (state: any) => ({
    authState: state.authState,
    clientState: state.clientState,
});

export default connect(mapStateToProps, {})(AddessDetails);
