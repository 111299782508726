import React from 'react';

import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { StateInterface } from '../../interfaces/StateInterfaces';
// import { ActionsInterface } from '../../interfaces/ActionInterfaces';
// interface PrivateRouteProps extends RouteProps {
//     component: ConnectedComponent<React.FC<any>, Pick<any, any>>;
//     authState: AuthState;
// }
const PrivateRoute: React.FC<any> = ({
    component: Component,
    authState: {
        isAuth,
        user: { isConfirmed },
    },
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                !isAuth ? <Redirect to='/login' /> : isConfirmed ? <Component {...props} /> : <Redirect to='/login' />
            }
        />
    );
};

const mapStateToProps = (state: StateInterface) => ({
    authState: state.authState,
});
export default connect(mapStateToProps)(PrivateRoute);
