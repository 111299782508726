import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Room, System } from '../../interfaces/StateInterfaces';
import { CreateBtn, HeaderOne, InfoBlock, ListRow } from '../styled/common';
import { DetailsContainer } from '../styled/containers';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { CardLables } from '../styled/card';
import { handleCrumb } from '../../reducers/adminActions';
import Loading from '../Loading';
const RoomDetails: React.FC<any> = ({
    location: { state },
    clientState: { clientLoading, singleClient },
    authState: {
        user: {
            firstName,
            lastName,
            companyId: { legalName },
        },
    },
}) => {
    if (Object.keys(singleClient).length === 0) {
        return <Redirect to='/' />;
    }
    const singleRoom: Room = singleClient.address
        .filter((e: any) => e._id === state.addressId)[0]
        .rooms.filter((e: any) => e._id === state._id)[0];

    const { name, area, roomType, roomComment, dateCreated, volume } = singleRoom || {};

    if (!clientLoading) {
        return (
            <>
                <HeaderOne style={{ fontSize: '42px', marginBottom: '30px' }}>Помещение</HeaderOne>

                {singleRoom && Object.keys(singleRoom).length && (
                    <DetailsContainer>
                        <InfoBlock>
                            <div className='infoblock-div'>
                                <HeaderOne>Данни за помещението</HeaderOne>
                                <CreateBtn to={{ pathname: '/room', state: { data: singleRoom } }}>
                                    Редактирай
                                </CreateBtn>
                            </div>

                            <div className='infoblock-div'>
                                <span>Наименование</span> <p>{name}</p>
                            </div>
                            <div className='infoblock-div'>
                                <span>Тип</span> <p>{roomType}</p>
                            </div>
                            <div className='infoblock-div'>
                                <span>Обем</span> <p>{volume}</p>
                            </div>
                            <div className='infoblock-div'>
                                <span>Площ</span> <p>{area}</p>
                            </div>

                            {/* <div className='infoblock-div'>
                                <span>Добавен от</span>{' '}
                                <p>
                                    {' '}
                                    {firstName + ' ' + lastName + ' ' + legalName + ' на '}{' '}
                                    <Moment format='DD-MM-YYYYг.'>{dateCreated}</Moment>
                                </p>
                            </div> */}
                            <div className='infoblock-div'>
                                <span>Допълнителна информация</span> <p>{roomComment}</p>
                            </div>
                        </InfoBlock>

                        <InfoBlock>
                            <div>
                                <HeaderOne>Системи</HeaderOne>
                                <CreateBtn to={{ pathname: '/system', state: singleRoom }}>Създай система</CreateBtn>
                            </div>

                            <CardLables>
                                <p>Наименование</p>
                                <p>Тип</p>
                                <p>Системи</p>
                                <p>виж</p>
                            </CardLables>

                            {singleRoom.systemsInRoom?.length ? (
                                singleRoom.systemsInRoom?.map((e: System) => (
                                    <ListRow key={e._id}>
                                        <div>
                                            <span>Сериен номер:</span>
                                            <p>{e.systemSn}</p>
                                        </div>
                                        <div>
                                            <span>Тип:</span>
                                            <p>{e.thermoRegulatorModel}</p>
                                        </div>
                                        <div>
                                            <span>Нагреватели:</span>
                                            <p>{e.systemComponents.length}</p>
                                        </div>
                                        <div>
                                            <Link
                                                key={e._id}
                                                to={{ pathname: '/systemdetails', state: e }}
                                                style={{
                                                    display: 'flex',
                                                    height: '100%',
                                                    width: 'fit-content',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faInfoCircle} size='2x' color={'#e45441'} />
                                            </Link>
                                        </div>
                                    </ListRow>
                                ))
                            ) : (
                                <HeaderOne style={{ margin: '20px' }}>Все още няма добавени системи</HeaderOne>
                            )}
                        </InfoBlock>
                    </DetailsContainer>
                )}
            </>
        );
    } else {
        return <Loading />;
    }
};
const mapStateToProps = (state: any) => ({
    authState: state.authState,
    clientState: state.clientState,
});
export default connect(mapStateToProps, { handleCrumb })(RoomDetails);
