import styled from 'styled-components';

export const StyledPagination = styled.div`
  width: 100%;
  margin-top: 40px;
  & ul {
    display: flex;
    width: 100%;
    padding: 20px;
    list-style: none;
    justify-content: center;
    gap: 10px;
    & li {
      border-radius: 5px;
      background-color: ${({ theme }) => theme.secondary};
      & a {
        display: inline-block;
        padding: 10px 15px;
        color: ${({ theme }) => theme.light};
        cursor: pointer;
      }
      &.active {
        background-color: ${({ theme }) => theme.light};
        border: 1px solid ${({ theme }) => theme.secondary};
        & a {
          color: ${({ theme }) => theme.secondary};
        }
      }
    }
  }
`;
