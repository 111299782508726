import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { User, AdminState, StateInterface, SinglePartner } from '../../interfaces/StateInterfaces';
import { CardBody } from '../styled/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
interface Data {
  data: User;
  adminState?: AdminState;
}
const UserCard: React.FC<Data> = ({
  data: { _id, dateCreated, firstName, lastName, email, isConfirmed, isMaster, isAdmin, companyId },
  adminState,
}) => {
  let partner;
  if (adminState) {
    partner = adminState.partners.filter((e: SinglePartner) => e._id === JSON.parse(JSON.stringify(companyId)))[0];
  }

  return (
    <>
      <CardBody>
        <div>
          <span>Име</span> <p>{firstName + ' ' + lastName}</p>
        </div>
        <div>
          <span>Имейл</span> <p>{email}</p>
        </div>

        <div>
          <span>Потвърден</span> <p>{isConfirmed ? 'Да' : 'Не'}</p>
        </div>

        <div>
          <span>Ниво на достъп</span>
          <p>{isMaster ? 'Пълен' : isAdmin ? 'Административен' : 'Контрагент'}</p>
        </div>
        <div>
          <span>Контрагент</span>
          <p>{partner && partner.legalName}</p>
        </div>
        <div>
          <span>Създаден</span>
          <p>
            <Moment format='DD-MM-YYYY'>{dateCreated}</Moment>
          </p>
        </div>

        <div>
          <p style={{ height: '100%' }}>
            <Link
              style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}
              to={{ pathname: '/userdetails', state: { id: _id } }}
            >
              <FontAwesomeIcon size='2x' icon={faInfoCircle} color={'#e45441'} />
            </Link>
          </p>
        </div>
      </CardBody>
    </>
  );
};
const mapStateToProps = (state: StateInterface) => ({
  adminState: state.adminState,
});
export default connect(mapStateToProps, {})(UserCard);
