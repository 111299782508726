import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createSystem, editSystem } from '../../reducers/clientActions';
import { FormContainer, FormField, FormGroup, InputLable, FormSubmit, FormTextArea, Required } from '../styled/forms';
import { HeaderOne } from '../styled/common';
const CreateSystem: React.FC<any> = ({
    createSystem,
    editSystem,
    location: { state },
    clientState: {
        singleClient: { _id },
    },
}) => {
    const editData = state.hasOwnProperty('thermoRegulatorSN') ? state : null;
    const { addressId, clientId } = state;

    const [id, setId] = useState('');
    const [formData, setFormData] = useState(
        editData || {
            thermoRegulatorModel: '',
            thermoRegulatorSN: '',
            protection: !'',
            floorType: '',
            netType: '',
            systemComment: '',
            addressId,
            clientId,
            dateInstalled: '',
            roomId: state._id,
            totalR: 0,
            totalI: 0,
            surfaceTemp: 0,
        }
    );
    if (!_id) {
        return <Redirect to={{ pathname: '/' }} />;
    }

    if (id) {
        return <Redirect to={{ pathname: '/systemdetails', state: id }} />;
    }
    const onchangeHander = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.name === 'protection' ? !!Number(e.target.value) : e.target.value,
        });
    };
    return (
        <>
            <FormContainer
                onSubmit={(e) => {
                    e.preventDefault();
                    if (editData) {
                        editSystem(formData, setId);
                    } else {
                        createSystem(formData, setId);
                    }
                }}
            >
                <HeaderOne> {editData ? 'Промяна на данните' : 'Създаване на система'} </HeaderOne>
                <FormGroup>
                    <InputLable>
                        SN на терморегулатор <Required>*</Required>
                    </InputLable>
                    <FormField name='thermoRegulatorSN' onChange={onchangeHander} value={formData.thermoRegulatorSN} />
                </FormGroup>
                <FormGroup>
                    <InputLable>
                        Модел на терморегулатор <Required>*</Required>
                    </InputLable>
                    <FormField
                        name='thermoRegulatorModel'
                        onChange={onchangeHander}
                        value={formData.thermoRegulatorModel}
                    />
                </FormGroup>

                <FormGroup>
                    <InputLable>
                        Вид настилка <Required> *</Required>
                    </InputLable>
                    <FormField name='floorType' onChange={onchangeHander} value={formData.floorType} />
                </FormGroup>
                <FormGroup>
                    <InputLable>
                        Вид мрежа <Required> *</Required>
                    </InputLable>
                    <FormField name='netType' onChange={onchangeHander} value={formData.netType} />
                </FormGroup>
                <FormGroup style={{ borderBottom: '1px solid #627894' }}>
                    <InputLable>
                        Задействане на ДТЗ <Required> *</Required>
                    </InputLable>{' '}
                    <InputLable className='radio'>
                        Да
                        <FormField
                            name='protection'
                            type='radio'
                            onChange={onchangeHander}
                            value='1'
                            checked={formData.protection}
                        />
                    </InputLable>{' '}
                    <InputLable className='radio'>
                        Не
                        <FormField
                            name='protection'
                            type='radio'
                            onChange={onchangeHander}
                            value='0'
                            checked={!formData.protection}
                        />
                    </InputLable>{' '}
                </FormGroup>
                <FormGroup>
                    <InputLable>
                        Дата на монтаж <Required> *</Required>
                    </InputLable>
                    <FormField
                        name='dateInstalled'
                        type='date'
                        onChange={onchangeHander}
                        value={formData.dateInstalled && formData.dateInstalled.slice(0, 10)}
                        disabled={editData?.dateInstalled}
                    />
                </FormGroup>

                {editData && (
                    <FormGroup>
                        <InputLable>Общо съпротивление</InputLable>
                        <FormField name='totalR' onChange={onchangeHander} value={formData.totalR} />
                    </FormGroup>
                )}
                {editData && (
                    <FormGroup>
                        <InputLable>Номинална сила на тока</InputLable>
                        <FormField name='totalI' onChange={onchangeHander} value={formData.totalI} />
                    </FormGroup>
                )}
                {editData && (
                    <FormGroup>
                        <InputLable>Повърхностна температура</InputLable>
                        <FormField name='surfaceTemp' onChange={onchangeHander} value={formData.surfaceTemp} />
                    </FormGroup>
                )}
                <FormGroup>
                    <InputLable>Допълнителна информация</InputLable>
                    <FormTextArea name='systemComment' onChange={onchangeHander} value={formData.systemComment} />
                </FormGroup>
                <FormSubmit>{editData ? 'Запази промените' : 'Създай система'}</FormSubmit>
            </FormContainer>
        </>
    );
};
const mapStateToProps = (state: any) => ({
    clientState: state.clientState,
});
export default connect(mapStateToProps, { createSystem, editSystem })(CreateSystem);
