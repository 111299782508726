import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { PrintContainer } from '../styled/containers';
import { PrintBtn } from '../styled/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/logo-itlo.png';

var Barcode = require('react-barcode');

const PrintComponent: React.FC<any> = ({
    data: {
        dateCreated,
        systemComponents,
        thermoRegulatorModel,
        systemSn,
        thermoRegulatorSN,

        models,
    },
}) => {
    const totalSystemPower = systemComponents
        .map((item: any) => {
            let power = 0;
            models.map((model: any) => {
                if (model.modelNumber === item.modelNumber) {
                    power = model.nominalPower;
                }
                return;
            });
            if (power) {
                return power;
            }
        })
        .reduce((a: any, b: any) => a + b, 0);

    const componentRef = useRef(null);

    return (
        <>
            <ReactToPrint
                trigger={() => (
                    <PrintBtn>
                        <FontAwesomeIcon icon={faPrint} /> Етикет
                    </PrintBtn>
                )}
                content={() => componentRef.current}
            />
            <PrintContainer ref={componentRef}>
                <div style={{ display: 'flex' }}>
                    <img src={logo} style={{ height: '40px' }} alt='' />
                    <p style={{ marginLeft: '40px', display: 'inline-block' }}>
                        ИНОВАТИВНИ ТЕХНОЛОГИИ ЗА ЛЪЧИСТО ОТОПЛЕНИЕ ООД
                    </p>
                </div>
                <h2 style={{ marginLeft: 'auto', marginRight: 'auto' }}>Система AHT SSRH</h2>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                    <p>Захранващо напрежение: {' 230 V'}</p>
                    <p>Топлинна мощност: {totalSystemPower + ' W'}</p>
                    <p>Регулатор модел: {thermoRegulatorModel}</p>
                    <p>Регулатор SN: {thermoRegulatorSN}</p>

                    <p>
                        Нагреватели:
                        {' ' + systemComponents.map((e: any) => e.serialNumber).join(', ')}
                    </p>
                </div>
                <div style={{ margin: 'auto' }}>
                    <Barcode value={systemSn} height={40} displayValue={false} marginLeft={70} marginRight={100} />
                </div>
                <p style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '-10px' }}>SN: {systemSn}</p>
                {/* <div style={{ display: 'flex' }}> */}
                <p>https://itlo-bg.com</p> <p> Тел. 0876500580, 0879500580</p>
                {/* </div> */}
            </PrintContainer>
        </>
    );
};

export default PrintComponent;
