import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const JsonData: React.FC<any> = ({ itemState }) => {
    useEffect(() => {
        if (itemState.items.length) {
            var jsonse = JSON.stringify(itemState.items);

            var blob = new Blob([jsonse], { type: 'application/json' });
            var url = URL.createObjectURL(blob);

            var a = document.createElement('a');
            a.href = url;
            a.download = 'backup.json';
            a.textContent = 'Download backup.json';

            a.click();
        }
    }, [itemState.items]);

    return <></>;
};

const mapStateToProps = (state: any) => ({
    itemState: state.itemState,
});

export default connect(mapStateToProps)(JsonData);
