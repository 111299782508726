import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createAddress, editAddress } from '../../reducers/clientActions';
import { FormContainer, FormField, FormGroup, InputLable, FormSubmit, FormTextArea, Required } from '../styled/forms';
import { HeaderOne } from '../styled/common';
import { dataTrim } from '../../utils/formDataTrim';
const CreateAddress: React.FC<any> = ({
  createAddress,
  editAddress,
  location: { state },
  clientState: {
    singleClient: { _id },
  },
}) => {
  const editData = state;

  const [id, setId] = useState('');
  const [formData, setFormData] = useState(
    editData || {
      country: 'България',
      town: '',
      district: '',
      streetAddress: '',
      zipCode: '',
      addressComment: '',
      clientId: _id,
    }
  );
  if (!_id) {
    return <Redirect to={{ pathname: '/' }} />;
  }
  if (id) {
    return <Redirect to={{ pathname: '/addressdetails', state: id }} />;
  }
  const onchangeHander = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          if (editData) {
            editAddress(dataTrim(formData), setId);
          } else {
            createAddress(dataTrim(formData), setId);
          }
        }}
      >
        <HeaderOne> {editData ? 'Промяна на данните' : 'Създаване на адрес'} </HeaderOne>
        <FormGroup>
          <InputLable>
            Държава <Required>*</Required>
          </InputLable>
          <FormField name='country' onChange={onchangeHander} value={formData.country} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Град <Required>*</Required>
          </InputLable>
          <FormField name='town' onChange={onchangeHander} value={formData.town} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Община <Required>*</Required>
          </InputLable>
          <FormField name='district' onChange={onchangeHander} value={formData.district} />
        </FormGroup>
        <FormGroup>
          <InputLable>Адрес</InputLable>
          <FormField name='streetAddress' onChange={onchangeHander} value={formData.streetAddress} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Пощенски код <Required>*</Required>
          </InputLable>
          <FormField name='zipCode' onChange={onchangeHander} value={formData.zipCode} />
        </FormGroup>
        <FormGroup>
          <InputLable>Допълнителна информация</InputLable>
          <FormTextArea name='addressComment' onChange={onchangeHander} value={formData.addressComment} />
        </FormGroup>

        <FormSubmit>{editData ? 'Запази промените' : 'Създай адрес'}</FormSubmit>
      </FormContainer>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  clientState: state.clientState,
});
export default connect(mapStateToProps, { createAddress, editAddress })(CreateAddress);
