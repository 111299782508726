import axios, { AxiosInstance } from 'axios';

const axiosInstance: AxiosInstance = (() => {
  const instance = axios.create({});
  instance.interceptors.request.use(function (config) {
    const url = config.url;
    if (url === '/auth/login' || url === '/api/forgotpassword' || url === '/api/changepass') {
      return config;
    } else {
      const token = localStorage.getItem('itlobg-token');

      config.headers['x-auth-token'] = token;
      return config;
    }
  });
  return instance;
})();
export default axiosInstance;
