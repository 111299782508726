import {
    CREATE_ITEM,
    CREATE_MODEL,
    EDIT_ITEM,
    EDIT_MODEL,
    GET_ITEMS,
    GET_MODELS,
    ITEM_LOADING,
    LOGOUT,
    UPDATE_ITEMS,
} from './types';
const initialState = {
    items: [],
    models: [],
    singleItem: {},

    itemLoading: false,
};

const itemState = (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case CREATE_ITEM:
            return { ...state, items: [payload, ...state.items] };
        case EDIT_ITEM:
            return { ...state, items: [payload, ...state.items.filter((e: any) => e._id !== payload._id)] };
        case ITEM_LOADING:
            return { ...state, itemLoading: true };
        case GET_ITEMS:
            return { ...state, items: payload, itemLoading: false };
        case GET_MODELS:
            return { ...state, models: payload };
        case LOGOUT:
            return { ...initialState };
        case UPDATE_ITEMS:
            return { ...state, items: [payload, ...state.items.filter((e: any) => e._id !== payload._id)] };
        case CREATE_MODEL:
        case EDIT_MODEL:
            return { ...state, models: [payload, ...state.models.filter((e: any) => e._id !== payload._id)] };
        default:
            return state;
    }
};
export default itemState;
