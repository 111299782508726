import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createClient, editClient } from '../../reducers/clientActions';
import { FormContainer, FormField, FormGroup, InputLable, FormSubmit, FormTextArea, Required } from '../styled/forms';
import { HeaderOne } from '../styled/common';
import { dataTrim } from '../../utils/formDataTrim';
const CreateClient: React.FC<any> = ({ createClient, editClient, location: { state } }) => {
  const editData = state;
  const [id, setId] = useState('');
  const [formData, setFormData] = useState(
    editData || {
      clientLegalName: '',
      clientEIK: '',
      firstName: '',
      middleName: '',
      lastName: '',
      baseAddress: '',
      email: '',
      phone: '',
      clientComment: '',
    }
  );
  if (id) {
    return <Redirect to={{ pathname: '/clientdetails', state: { id } }} />;
  }
  const onchangeHander = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          if (typeof editData === 'object') {
            editClient(dataTrim(formData), setId);
          } else {
            createClient(dataTrim({ ...formData, companyId: editData }), setId);
          }
        }}
      >
        <HeaderOne> {editData ? 'Промяна на данните' : 'Създаване на клиент'} </HeaderOne>
        <FormGroup>
          <InputLable>Фирма</InputLable>
          <FormField name='clientLegalName' onChange={onchangeHander} value={formData.clientLegalName} />
        </FormGroup>
        <FormGroup>
          <InputLable>ЕИК</InputLable>
          <FormField name='clientEIK' onChange={onchangeHander} value={formData.clientEIK} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Име <Required>*</Required>
          </InputLable>
          <FormField name='firstName' onChange={onchangeHander} value={formData.firstName} />
        </FormGroup>
        <FormGroup>
          <InputLable>Презиме</InputLable>
          <FormField name='middleName' onChange={onchangeHander} value={formData.middleName} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Фамилия <Required>*</Required>
          </InputLable>
          <FormField name='lastName' onChange={onchangeHander} value={formData.lastName} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Адрес за кореспонденция <Required>*</Required>
          </InputLable>
          <FormField name='baseAddress' onChange={onchangeHander} value={formData.baseAddress} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Емейл <Required>*</Required>
          </InputLable>
          <FormField type='email' name='email' onChange={onchangeHander} value={formData.email} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Телефон <Required>*</Required>
          </InputLable>
          <FormField name='phone' onChange={onchangeHander} value={formData.phone} />
        </FormGroup>
        <FormGroup>
          <InputLable>Допълнилна информация </InputLable>
          <FormTextArea name='clientComment' onChange={onchangeHander} value={formData.clientComment} />
        </FormGroup>

        <FormSubmit>{editData ? 'Запази промените' : 'Създай клиент'}</FormSubmit>
      </FormContainer>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  clientState: state.clientState,
});
export default connect(mapStateToProps, { createClient, editClient })(CreateClient);
