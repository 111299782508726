import React, { useState } from 'react';
import { connect } from 'react-redux';
import { StateInterface, User } from '../../interfaces/StateInterfaces';
import { StyledPagination } from '../styled/pagination';
import ReactPaginate from 'react-paginate';
import { ContentContainer, ContainerRow } from '../styled/containers';
import { CardLables } from '../styled/card';
import { Search, ShowOnPageContainer, ShowOnPageBtn, CreateBtn, HeaderOne } from '../styled/common';
import UserCard from '../layout/UserCard';

const Users: React.FC<StateInterface> = ({ adminState: { users } }) => {
  const [filtered, setFiltered] = useState<User[]>(users);
  const [currentPage, setCurrentPage] = useState(Number(0));
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(Math.ceil(filtered.length / itemsPerPage));

  const filterHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) {
      const mapped = users.filter(
        (user) =>
          Object.values(user)
            .filter((v) => typeof v === 'string' || typeof v === 'number')
            .filter((data) => {
              return data.toString().toLowerCase().includes(e.target.value.toLowerCase());
            }).length
      );
      setFiltered([...mapped]);
      setPageCount(mapped.length / itemsPerPage);
      setCurrentPage(0);
    } else {
      setFiltered(users);
      setPageCount(users.length / itemsPerPage);
    }
  };

  const handleClick = (e: any) => {
    setCurrentPage(Number(e.selected));
  };

  const showOnPage = (e: React.MouseEvent<HTMLButtonElement>, num: Number) => {
    //@ts-ignore
    const elem: HTMLButtonElement = e.target;
    const elems = elem!.parentElement!.childNodes;
    elem.classList.add('active');
    Array.prototype.slice
      .call(elems)
      .filter((el) => el !== elem)
      .map((e) => e.classList.remove('active'));

    setCurrentPage(Number(0));
    setPageCount(Math.ceil(filtered.length / Number(num)));
    setItemsPerPage(Number(num));
  };

  return (
    <>
      <HeaderOne>ПОТРЕБИТЕЛИ</HeaderOne>
      <ContainerRow>
        <ShowOnPageContainer>
          <ShowOnPageBtn className='active' onClick={(e) => showOnPage.call(undefined, e, 5)}>
            5
          </ShowOnPageBtn>
          <ShowOnPageBtn onClick={(e) => showOnPage.call(undefined, e, 10)}>10</ShowOnPageBtn>
          <ShowOnPageBtn onClick={(e) => showOnPage.call(undefined, e, 20)}>20</ShowOnPageBtn>
          <ShowOnPageBtn onClick={(e) => showOnPage.call(undefined, e, 30)}>30</ShowOnPageBtn>
          <ShowOnPageBtn onClick={(e) => showOnPage.call(undefined, e, 60)}>60</ShowOnPageBtn>
        </ShowOnPageContainer>
        <Search name='search' type='text' placeholder='Търсене...' onChange={filterHandler} />
        <CreateBtn to='/user'>Създай потребител</CreateBtn>
      </ContainerRow>
      <ContentContainer>
        <CardLables>
          <p>Име</p>
          <p>Емайл</p>
          <p>Потвърден</p>
          <p>Достъп</p>
          <p>Фирма</p>
          <p>Дата на създаване</p>
          <p>Виж</p>
        </CardLables>
        {filtered
          .slice(itemsPerPage * currentPage, itemsPerPage * (currentPage + 1))
          .sort()
          .map((e, i) => (
            <UserCard key={e._id} data={e} />
          ))}
        <StyledPagination>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handleClick}
            // containerClassName={'pagination'}
            // subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </StyledPagination>
      </ContentContainer>
    </>
  );
};
const mapStateToProps = (state: StateInterface) => ({
  clientState: state.clientState,
  adminState: state.adminState,
});
export default connect(mapStateToProps, {})(Users);
