import React from 'react';
import { connect } from 'react-redux';
import { getAllClients } from '../../reducers/clientActions';
import { StateInterface } from '../../interfaces/StateInterfaces';
import { ActionsInterface } from '../../interfaces/ActionInterfaces';
import Loading from '../Loading';
import ClientCard from '../layout/ClientCard';
import ElementCard from '../layout/ElementCard';
import { ContentContainer, ContainerRow } from '../styled/containers';
import { CreateBtn, HeaderOne, HeaderTwo } from '../styled/common';
import { CardLables } from '../styled/card';

const HomeComponent: React.FC<StateInterface & ActionsInterface> = ({
  clientState: { clients, clientLoading },
  itemState: { items },
  authState: { user },
}) => {
  if (!clientLoading) {
    return (
      <>
        <ContainerRow>
          <HeaderOne>Най-нови Клиенти</HeaderOne>
          <CreateBtn to='/client'>Създай клиент</CreateBtn>
        </ContainerRow>
        <ContentContainer>
          <CardLables>
            <p>Име</p>
            <p>Тел</p>
            <p>Имейл</p>
            <p>Контрагент</p>
            <p>Дата на създаване</p>

            <p>Виж</p>
          </CardLables>
          {clients.length ? clients.slice(0, 5).map((e) => <ClientCard key={e._id} data={e} />) : <h2>Няма клиенти</h2>}
        </ContentContainer>
        <ContainerRow>
          <HeaderOne>Нeзавършени елементи</HeaderOne>
          {user.isAdmin && <CreateBtn to='/item'>Създай нагревател</CreateBtn>}
        </ContainerRow>
        <ContentContainer style={{ marginTop: '20px' }}>
          <CardLables>
            <p>Модел</p>
            <p>SN</p>
            <p>1во измерване</p>
            <p>2ро измерване</p>
            <p>3то измерване</p>
            <p>Статус</p>
            <p>дата</p>
            {user.isAdmin && <p>Прехвърли</p>}
            <p>Виж</p>
          </CardLables>
          {items.length ? (
            items.filter((e) => !e.testCompleted).length ? (
              items
                .filter((e) => !e.testCompleted)
                .slice(0, 5)
                .map((e) => <ElementCard key={e._id} data={e} />)
            ) : (
              <HeaderTwo>Няма незавършени нагреватели</HeaderTwo>
            )
          ) : (
            <HeaderTwo>Няма нагреватели</HeaderTwo>
          )}
        </ContentContainer>
      </>
    );
  } else {
    return <Loading />;
  }
};

const mapStateTopProps = (state: StateInterface) => ({
  authState: state.authState,
  clientState: state.clientState,
  itemState: state.itemState,
});
export default connect(mapStateTopProps, { getAllClients })(HomeComponent);
