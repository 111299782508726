import React, { useState } from 'react';
import { connect } from 'react-redux';

import { createModel, editModel } from '../../reducers/itemActions';
import {
  FormField,
  FormGroup,
  InputLable,
  FormSubmit,
  FormTextArea,
  Required,
  BigFormContainer,
} from '../styled/forms';
import { HeaderOne } from '../styled/common';
import { dataTrim } from '../../utils/formDataTrim';
import { Redirect } from 'react-router';
const CreateModel: React.FC<any> = ({ createModel, editModel, location: { state } }) => {
  const editData = state;
  const [id, setId] = useState('');
  const [formData, setFormData] = useState(
    editData || {
      modelNumber: '',
      width: '',
      length: '',
      heaterColor: '',
      heaterWidth: '',
      heaterGap: '',
      heaterCount: '',
      step: '',
      nominalPower: '',
      nominalI: '',
      minR: '',
      maxR: '',
      connectorType: '',
      connectorCount: '',
      wireType: '',
      wireCount: '',
      modelComment: '',
    }
  );
  if (id) {
    return <Redirect to={{ pathname: '/modeldetails', state: { id } }} />;
  }
  const onchangeHander = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <>
      <BigFormContainer
        onSubmit={(e) => {
          e.preventDefault();
          if (editData) {
            editModel(dataTrim({ ...formData, heatingArea: (formData.width * formData.length) / 10000 }), setId);
          } else {
            createModel(dataTrim(formData), setId);
          }
        }}
      >
        <HeaderOne style={{ width: '100%', textAlign: 'center', marginBottom: '20px' }}>
          {' '}
          {editData ? 'Промяна на данните' : 'Създаване на модел'}{' '}
        </HeaderOne>
        <FormGroup>
          <InputLable>
            Модел <Required>*</Required>
          </InputLable>
          <FormField name='modelNumber' onChange={onchangeHander} value={formData.modelNumber} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Ширина в "cm"<Required> *</Required>
          </InputLable>
          <FormField name='width' onChange={onchangeHander} value={formData.width} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Дължина в "cm"<Required> *</Required>
          </InputLable>
          <FormField name='length' onChange={onchangeHander} value={formData.length} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Цвят нагревателна лента <Required> *</Required>
          </InputLable>
          <FormField name='heaterColor' onChange={onchangeHander} value={formData.heaterColor} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Ширина на нагревателна лента в "mm"<Required> *</Required>
          </InputLable>
          <FormField name='heaterWidth' onChange={onchangeHander} value={formData.heaterWidth} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Разтояние между нагревателните ленти в "mm"<Required> *</Required>
          </InputLable>
          <FormField name='heaterGap' onChange={onchangeHander} value={formData.heaterGap} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Брой редове нагревателни ленти <Required> *</Required>
          </InputLable>
          <FormField name='heaterCount' onChange={onchangeHander} value={formData.heaterCount} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            "Стъпка" нагревателни ленти в "mm"<Required> *</Required>
          </InputLable>
          <FormField name='step' onChange={onchangeHander} value={formData.step} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Номинална мощност в "W"<Required> *</Required>
          </InputLable>
          <FormField name='nominalPower' onChange={onchangeHander} value={formData.nominalPower} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Номинална сила на тока в "А"<Required> *</Required>
          </InputLable>
          <FormField name='nominalI' onChange={onchangeHander} value={formData.nominalI} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Минимално съпротивление в "Ω"<Required> *</Required>
          </InputLable>
          <FormField name='minR' onChange={onchangeHander} value={formData.minR} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Максимално съпротивление в "Ω"<Required> *</Required>
          </InputLable>
          <FormField name='maxR' onChange={onchangeHander} value={formData.maxR} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Тип конектор <Required>*</Required>
          </InputLable>
          <FormField name='connectorType' onChange={onchangeHander} value={formData.connectorType} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Брой конектори <Required> *</Required>
          </InputLable>
          <FormField name='connectorCount' onChange={onchangeHander} value={formData.connectorCount} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Вид проводници <Required> *</Required>
          </InputLable>
          <FormField name='wireType' onChange={onchangeHander} value={formData.wireType} />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Брой проводници <Required>*</Required>
          </InputLable>
          <FormField name='wireCount' onChange={onchangeHander} value={formData.wireCount} />
        </FormGroup>
        <FormGroup>
          <InputLable>Допълнителна информация</InputLable>
          <FormTextArea name='modelComment' onChange={onchangeHander} value={formData.modelComment} />
        </FormGroup>

        <FormSubmit>{editData ? 'Запази промените' : 'Създай модел'}</FormSubmit>
      </BigFormContainer>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  clientState: state.clientState,
});
export default connect(mapStateToProps, { createModel, editModel })(CreateModel);
