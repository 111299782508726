import styled from 'styled-components';

export const FormContainer = styled.form`
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 25px;
    padding: 40px;
    -webkit-box-shadow: 5px 7px 15px 2px rgba(0, 0, 0, 0.69);
    box-shadow: 5px 7px 15px 2px rgba(0, 0, 0, 0.69);
    border-radius: 5px;
    @media (min-width: 768px) {
        margin-top: auto;
        margin-bottom: auto;
    }
`;
export const BigFormContainer = styled.form`
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 25px;
    padding: 20px;
    -webkit-box-shadow: 5px 7px 15px 2px rgba(0, 0, 0, 0.69);
    box-shadow: 5px 7px 15px 2px rgba(0, 0, 0, 0.69);
    border-radius: 5px;
    @media (min-width: 768px) {
        padding: 40px;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        & > div {
            width: 45%;
        }
        & > button {
            width: 45%;
            margin-left: auto;
        }
    }
`;
export const FormField = styled.input`
    width: 100%;
    padding: 15px 15px 5px 15px;

    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.primary};

    &:webkit-autofill {
        background-color: white;
    }
`;
export const FormGroup = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    & small {
        padding: 5px 0;
        font-size: 10px;
        color: ${({ theme }) => theme.secondary};
    }
`;
export const InputLable = styled.label`
    position: absolute;
    padding: 0 10px;
    top: -13px;
    left: -10px;
    background-color: white;
    border-radius: 15px;
    font-size: 12px;

    color: ${({ theme }) => theme.primary};
    &.radio {
        position: relative;
        display: inline-block;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 20px;
        & > input {
            width: 20px;
            height: 20px;
            &:checked:after {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                /* top: 1px; */
                /* left: 1px; */
                position: relative;
                background-color: ${({ theme }) => theme.secondary};
                content: '';
                display: inline-block;
                visibility: visible;
            }
        }
    }
`;
export const FormSubmit = styled.button`
    width: 100%;
    padding: 15px;
    background-color: ${({ theme }) => theme.secondary};
    color: white;
    font-size: 20px;
    border: none;
    cursor: pointer;
`;
export const FormTextArea = styled.textarea`
    width: 100%;
    padding: 15px;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.primary};

    &:webkit-autofill {
        background-color: white;
    }
`;
export const Required = styled.span`
    color: red;
`;
export const ForgotPassBtn = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
    background-color: ${({ theme }) => theme.secondary};
    color: white;
    padding: 5px 10px;
    font-size: 14px;
`;

export const SelectField = styled.select`
    width: 100%;
    padding: 15px 15px 5px 15px;

    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.primary};

    &:webkit-autofill {
        background-color: white;
    }
`;
