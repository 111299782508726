import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ItemModel, ItemState, StateInterface } from '../../interfaces/StateInterfaces';
import { CardBody } from '../styled/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
interface Data {
    data: ItemModel;
    itemState: ItemState;
}
const ModelCard: React.FC<Data> = ({
    data,
    data: { maxR, minR, modelNumber, width, length, dateCreated, nominalPower },
    itemState: { models },
}) => {
    const model = models.filter((e) => e._id === data._id)[0];
    console.log(model);
    return (
        <>
            <CardBody>
                <div>
                    <span>Модел</span> <p>{modelNumber}</p>
                </div>

                <div>
                    <span>Съпротивление</span>
                    <p>{minR + 'Ω - ' + maxR + 'Ω'}</p>
                </div>
                <div>
                    <span>Размери</span>
                    <p>{length + 'cm х ' + width}cm.</p>
                </div>
                <div>
                    <span>2во измерване</span>
                    <p>~{nominalPower}W</p>
                </div>

                <div>
                    <span>Дата на производство</span>
                    <p>
                        <Moment format='DD-MM-YYYY'>{dateCreated}</Moment>
                    </p>
                </div>

                <div>
                    <p style={{ height: '100%' }}>
                        <Link
                            style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}
                            to={{ pathname: '/modeldetails', state: { id: model._id } }}
                        >
                            <FontAwesomeIcon size='2x' icon={faInfoCircle} color={'#e45441'} />
                        </Link>
                    </p>
                </div>
            </CardBody>
        </>
    );
};
const mapStateToProps = (state: StateInterface) => ({
    itemState: state.itemState,
});
export default connect(mapStateToProps, {})(ModelCard);
