import React from 'react';
import { connect } from 'react-redux';
import { StateInterface } from '../../interfaces/StateInterfaces';
import { MainbarInfo } from '../styled/navigation';
import { Logout } from '../../interfaces/ActionInterfaces';
import { logout } from '../../reducers/authActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/eu.jpg';

const MainNav: React.FC<StateInterface & Logout> = ({
    logout,
    authState: {
        isAuth,
        user: { firstName, lastName, companyId },
        authLoading,
    },
}) => {
    return (
        <MainbarInfo>
            <Link to='/eu' style={{ marginRight: 'auto' }}>
                <img src={logo} alt='' style={{ height: '65px' }} />
            </Link>
            {isAuth && !authLoading && (
                <>
                    <div>
                        <p>{companyId?.legalName}</p>
                    </div>
                    <div>
                        <p>
                            <FontAwesomeIcon icon={faUser} /> {firstName + '  ' + lastName}
                        </p>
                    </div>
                    <div>
                        <button
                            style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
                            onClick={logout}
                        >
                            <FontAwesomeIcon icon={faSignOutAlt} size='2x' style={{ color: 'white' }} />
                        </button>
                    </div>
                </>
            )}
        </MainbarInfo>
    );
};
const mapStateTopProps = (state: StateInterface) => ({
    authState: state.authState,
    clientState: state.clientState,
    itemState: state.itemState,
    adminState: state.adminState,
});
export default connect(mapStateTopProps, { logout })(MainNav);
