import { LOGIN_SUCCESS, GET_USER, LOGOUT, AUTH_LOADING } from './types';

const initialState = {
  token: localStorage.getItem('itlobg-token'),
  isAuth: !!localStorage.getItem('itlobg-token'),
  user: localStorage.getItem('itlobg-user') ? JSON.parse(localStorage.getItem('itlobg-user')!) : {},
  authLoading: false,
};

const authState = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH_LOADING:
      return { ...state, authLoading: true };
    case LOGIN_SUCCESS:
      localStorage.setItem('itlobg-token', payload);
      return { ...state, token: payload, isAuth: true };
    case GET_USER:
      localStorage.setItem('itlobg-user', JSON.stringify(payload));
      return { ...state, user: payload, authLoading: false };
    case LOGOUT:
      localStorage.clear();

      return { token: null, isAuth: false, user: {}, authLoading: false };
    default:
      return state;
  }
};
export default authState;
