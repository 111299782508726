import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  FormContainer,
  FormField,
  FormGroup,
  InputLable,
  FormSubmit,
  FormTextArea,
  Required,
  SelectField,
} from '../styled/forms';
import { HeaderOne } from '../styled/common';
import { dataTrim } from '../../utils/formDataTrim';
import { createUser, editUser } from '../../reducers/adminActions';
import { toastr } from 'react-redux-toastr';
const CreateUser: React.FC<any> = ({
  location: { state },
  clientState: {
    singleClient: { _id },
  },
  adminState: { partners },
  createUser,
  editUser,
}) => {
  const editData = state;
  const itloData = partners.filter((e: any) => e.EIK === 205279456)[0];
  const partnersData = partners.filter((e: any) => e.EIK !== 205279456);
  const [id, setId] = useState('');
  const [formData, setFormData] = useState(
    editData || {
      email: ' ',
      firstName: '',
      lastName: ' ',
      isAdmin: !!'',
      zipCode: '',
      addressComment: '',
      clientId: _id,
      password: '',
      rePassword: '',
      companyId: '',
    }
  );

  if (id) {
    return <Redirect to={{ pathname: '/userdetails', state: { id } }} />;
  }

  const onchangeHander = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.name === 'isAdmin' ? !!Number(e.target.value) : e.target.value,
    });
  };
  return (
    <>
      <FormContainer
        autoComplete='off'
        onSubmit={(e) => {
          e.preventDefault();
          if (editData) {
            editUser(dataTrim(formData), setId);
          } else {
            if (formData.companyId) {
              createUser(dataTrim(formData), setId);
            } else {
              toastr.error('Моля въведете контрагент', '');
            }
          }
        }}
      >
        <HeaderOne> {editData ? 'Промяна на данните' : 'Създаване на потребител'} </HeaderOne>

        <SelectField
          disabled={editData}
          autoComplete='off'
          name='companyId'
          onChange={onchangeHander}
          value={formData.companyId}
        >
          <option value={''}>Моля изберете контрагент </option>
          <option value={itloData?._id}>{itloData?.legalName} </option>
          {partnersData &&
            partnersData.map((e: any) => (
              <option key={e._id} value={e._id}>
                {e.legalName}
              </option>
            ))}
        </SelectField>
        <FormGroup>
          <InputLable>
            Email <Required>*</Required>
          </InputLable>
          <FormField
            disabled={editData}
            name='email'
            onChange={onchangeHander}
            type='email'
            placeholder='Моля въведете имейл'
            autoComplete='off'
            value={formData.email}
          />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Име <Required>*</Required>
          </InputLable>
          <FormField
            name='firstName'
            type='text'
            onChange={onchangeHander}
            value={formData.firstName}
            autoComplete='off'
          />
        </FormGroup>
        <FormGroup>
          <InputLable>
            Фамилия <Required>*</Required>
          </InputLable>
          <FormField
            name='lastName'
            type='text'
            onChange={onchangeHander}
            value={formData.lastName}
            autoComplete='off'
          />
        </FormGroup>
        <FormGroup>
          <InputLable>Парола</InputLable>
          <FormField
            disabled={editData}
            name='password'
            type='password'
            onChange={onchangeHander}
            value={formData.password}
            autoComplete='off'
          />
        </FormGroup>
        <FormGroup>
          <InputLable>Повтори парола</InputLable>
          <FormField
            disabled={editData}
            name='rePassword'
            type='password'
            onChange={onchangeHander}
            value={formData.rePassword}
            autoComplete='off'
          />
        </FormGroup>
        <FormGroup style={{ borderBottom: '1px solid #627894' }}>
          <InputLable>
            Администраторски права<Required> *</Required>
          </InputLable>{' '}
          <InputLable className='radio'>
            Да
            <FormField name='isAdmin' type='radio' onChange={onchangeHander} value='1' checked={formData.isAdmin} />
          </InputLable>{' '}
          <InputLable className='radio'>
            Не
            <FormField name='isAdmin' type='radio' onChange={onchangeHander} value='0' checked={!formData.isAdmin} />
          </InputLable>{' '}
        </FormGroup>
        <FormGroup>
          <InputLable>Допълнителна информация</InputLable>
          <FormTextArea name='addressComment' onChange={onchangeHander} value={formData.addressComment} />
        </FormGroup>

        <FormSubmit>{editData ? 'Запази промените' : 'Създай потребител'}</FormSubmit>
      </FormContainer>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  clientState: state.clientState,
  adminState: state.adminState,
});
export default connect(mapStateToProps, { createUser, editUser })(CreateUser);
