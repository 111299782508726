import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SingleClient, User } from '../../interfaces/StateInterfaces';
import { CreateBtn, HeaderOne, InfoBlock, ListRow } from '../styled/common';
import { DetailsContainer } from '../styled/containers';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { CardLables } from '../styled/card';

import Loading from '../Loading';
const UserDetails: React.FC<any> = ({
  location: { state },
  clientState: { clientLoading, clients },
  adminState: { users },
}) => {
  const singleUser: User = users.filter((e: any) => e._id === state.id)[0];
  const userClients = clients.filter((e: any) => e.addedBy === state.id);

  const { firstName, lastName, email, isConfirmed, isAdmin, isMaster } = singleUser || {};

  if (!clientLoading) {
    return (
      <>
        <HeaderOne style={{ fontSize: '42px', marginBottom: '30px' }}> ПОТРЕБИТЕЛ</HeaderOne>

        {singleUser && Object.keys(singleUser).length && (
          <DetailsContainer>
            <InfoBlock>
              <div className='infoblock-div'>
                <HeaderOne>Данни за потребителя</HeaderOne>

                <CreateBtn style={{ marginLeft: '20px' }} to={{ pathname: '/user', state: singleUser }}>
                  Редактирай
                </CreateBtn>
              </div>

              <div className='infoblock-div'>
                <span>Име</span> <p>{firstName + ' ' + lastName}</p>
              </div>
              <div className='infoblock-div'>
                <span>Имейл</span> <p>{email}</p>
              </div>
              <div className='infoblock-div'>
                <span style={{ color: isConfirmed ? 'gray' : 'red' }}>Потвърден</span>{' '}
                <p style={{ color: isConfirmed ? 'inherit' : 'red' }}>{isConfirmed ? 'Да' : 'Не'}</p>
              </div>
              <div className='infoblock-div'>
                <span>Пощенски код</span> <p>{isAdmin}</p>
              </div>
              <div className='infoblock-div'>
                <span>Адрес</span> <p>{isMaster}</p>
              </div>
              {/* <div className='infoblock-div'>
                <span>Добавен от</span>{' '}
                <p>
                  {' '}
                  {firstName + ' ' + lastName + ' ' + legalName + ' на '}{' '}
                  <Moment format='DD-MM-YYYYг.'>{dateCreated}</Moment>
                </p>
              </div> */}
              <div className='infoblock-div'>
                <span>Допълнителна информация</span> <p>{}</p>
              </div>
            </InfoBlock>

            <InfoBlock>
              <div>
                <HeaderOne>Клиенти</HeaderOne>
                <CreateBtn to={{ pathname: '/client' }}>Създай клиент</CreateBtn>
              </div>

              <CardLables>
                <p>Клиент</p>
                <p>Имейл</p>

                <p>виж</p>
              </CardLables>

              {userClients.length ? (
                userClients.map((e: SingleClient) => (
                  <ListRow key={e._id}>
                    <div>
                      <span>Наименование:</span>
                      <p>{e.clientLegalName ? e.clientLegalName : e.firstName + ' ' + e.lastName}</p>
                    </div>
                    <div>
                      <span>Емейл:</span>
                      <p>{e.email}</p>
                    </div>
                    <div>
                      <Link
                        key={e._id}
                        to={{ pathname: '/clientdetails', state: { id: e._id } }}
                        style={{
                          display: 'flex',
                          height: '100%',
                          width: 'fit-content',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} size='2x' color={'#e45441'} />
                      </Link>
                    </div>
                  </ListRow>
                ))
              ) : (
                <HeaderOne style={{ margin: '20px' }}>Този потребител няма добавени клиенти</HeaderOne>
              )}
            </InfoBlock>
          </DetailsContainer>
        )}
      </>
    );
  } else {
    return <Loading />;
  }
};
const mapStateToProps = (state: any) => ({
  authState: state.authState,
  clientState: state.clientState,
  adminState: state.adminState,
});

export default connect(mapStateToProps, {})(UserDetails);
