export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const GET_USER = 'GET_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';

export const CLIENT_LOADING = 'CLIENT_LOADING';
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_SINGLE_CLIENT = 'GET_SINGLE_CLIENT';
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const CLEAR_SINGLE_CLIENT = 'CLEAR_SINGLE_CLIENT';

export const CREATE_ADDRESS = 'CREATE_ADDRESS';
export const EDIT_ADDRESS = 'EDIT_ADDRESS';

export const CREATE_ROOM = 'CREATE_ROOM';
export const EDIT_ROOM = 'EDIT_ROOM';

export const CREATE_SYSTEM = 'CREATE_SYSTEM';
export const EDIT_SYSTEM = 'EDIT_SYSTEM';

export const GET_MODELS = 'GET_MODELS';

export const CREATE_ITEM = 'CREATE_ITEM';
export const EDIT_ITEM = 'EDIT_ITEM';
export const GET_ITEMS = 'GET_ITEMS';
export const ITEM_LOADING = 'ITEM_LOADING';
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const UPDATE_ITEMS = 'UPDATE_ITEMS';
export const GET_COMPANIES = 'GET_COMPANIES';
export const CREATE_PARTNER = 'CREATE_PARTNER';
export const EDIT_PARTNER = 'EDIT_PARTNER';
export const GET_USERS = 'GET_USERS';

export const HANDLE_CRUMB = 'HANDLE_CRUMB';
export const CLEAR_CRUMB = 'CLEAR_CRUMB';

export const CREATE_MODEL = 'CREATE_MODEL';
export const EDIT_MODEL = 'EDIT_MODEL';

export const CREATE_USER = 'CREATE_USER';
