import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AuthState } from '../interfaces/StateInterfaces';
import {
  GET_COMPANIES,
  GET_USERS,
  HANDLE_CRUMB,
  CLEAR_CRUMB,
  CREATE_USER,
  CREATE_PARTNER,
  EDIT_PARTNER,
} from './types';
import axiosInstance from '../utils/axiosInterceptor';

import { toastr } from 'react-redux-toastr';

export const getData = () => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    const companyRes = await axiosInstance.get('/api/company');
    const usersRes = await axiosInstance.get('/api/auth/all');
    dispatch({ type: GET_COMPANIES, payload: companyRes.data });
    dispatch({ type: GET_USERS, payload: usersRes.data });
  } catch (error) {
    if (error?.response?.status >= 500) {
      toastr.error('Сървърна грешка, моля опитайте отново след няколко минути', '');
    }
    error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
  }
};

export const handleCrumb = (location: any) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  dispatch({ type: HANDLE_CRUMB, payload: location });
};
export const clearCrumb = (location: any) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  const payload = location.pathname === '/' ? [location] : [{ pathname: '/' }, location];
  dispatch({ type: CLEAR_CRUMB, payload });
};

export const createUser = (data: any, setId: any) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    const res = await axiosInstance.post('/api/auth/register', data);
    dispatch({ type: CREATE_USER, payload: res.data });
    setId(res.data._id);
    toastr.success('Потребителят е създаден', '');
  } catch (error) {
    if (error?.response?.status >= 500) {
      toastr.error('Сървърна грешка, моля опитайте отново след няколко минути', '');
    } else {
      console.log(error);
      error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
    }
  }
};

export const editUser = (data: any, setId: any) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    const res = await axiosInstance.put('/api/auth/register', data);
    dispatch({ type: CREATE_USER, payload: res.data });
    setId(res.data._id);
    toastr.success('Промените са запаметени', '');
  } catch (error) {
    if (error?.response?.status >= 500) {
      toastr.error('Сървърна грешка, моля опитайте отново след няколко минути', '');
    } else {
      error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
    }
  }
};

export const createPartner = (data: any, setId: any) => async (
  dispatch: ThunkDispatch<AuthState, undefined, Action>
) => {
  try {
    const res = await axiosInstance.post('/api/company', data);
    dispatch({ type: CREATE_PARTNER, payload: res.data });
    setId(res.data._id);
    toastr.success('Контрагентът е създаден', '');
  } catch (error) {
    console.log(error?.response);
    if (error?.response?.status >= 500) {
      toastr.error('Сървърна грешка, моля опитайте отново след няколко минути', '');
    } else {
      error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
    }
  }
};
export const editPartner = (data: any, setId: any) => async (dispatch: ThunkDispatch<AuthState, undefined, Action>) => {
  try {
    const res = await axiosInstance.put('/api/company', data);
    dispatch({ type: EDIT_PARTNER, payload: res.data });
    setId(res.data._id);
    toastr.success('Промените са запаметени', '');
  } catch (error) {
    if (error?.response?.status >= 500) {
      toastr.error('Сървърна грешка, моля опитайте отново след няколко минути', '');
    } else {
      error?.response?.data.map((e: any) => toastr.error(e.msg, ''));
    }
  }
};
