import React, { useEffect } from 'react';
import { getSingleClient } from '../../reducers/clientActions';
import { StateInterface, DetailedClient, Address } from '../../interfaces/StateInterfaces';
import { connect } from 'react-redux';
import Loading from '../Loading';
import { CreateBtn, HeaderOne, InfoBlock, ListRow } from '../styled/common';
import Moment from 'react-moment';
import { DetailsContainer } from '../styled/containers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { CardLables } from '../styled/card';

const ClientDetails: React.FC<any> = ({
  getSingleClient,
  clientState: { singleClient, clientLoading },
  location: {
    state: { id },
  },
  location,
}) => {
  useEffect(() => {
    getSingleClient(id);
  }, [getSingleClient, id]);

  const {
    address,
    firstName,
    middleName,
    lastName,
    clientLegalName,
    baseAddress,
    phone,
    addedBy,
    email,
    companyId,
    clientEIK,
    dateCreated,
    clientComment,
  } = (singleClient as DetailedClient) || {};

  if (!clientLoading) {
    return (
      <>
        <HeaderOne style={{ fontSize: '42px', marginBottom: '30px' }}>КЛИЕНТ</HeaderOne>
        {singleClient && Object.keys(singleClient).length && (
          <DetailsContainer>
            <InfoBlock>
              <div className='infoblock-div'>
                <HeaderOne>Данни за клиента</HeaderOne>
                <CreateBtn to={{ pathname: '/client', state: singleClient }}>Редактирай</CreateBtn>
              </div>
              {clientLegalName && (
                <>
                  <div className='infoblock-div'>
                    <span>Фирма</span> <p>{clientLegalName}</p>
                  </div>
                  <div className='infoblock-div'>
                    <span>ЕИК</span> <p>{clientEIK}</p>
                  </div>
                </>
              )}
              <div className='infoblock-div'>
                <span>Име</span> <p>{' ' + firstName + (middleName ? ' ' + middleName + ' ' : ' ') + lastName}</p>
              </div>
              <div className='infoblock-div'>
                <span>Адрес за кореспондениця </span> <p>{baseAddress}</p>
              </div>
              <div className='infoblock-div'>
                <span>Тел.</span> <p>{phone}</p>
              </div>
              <div className='infoblock-div'>
                <span>Емейл</span> <p>{email}</p>
              </div>
              <div className='infoblock-div'>
                <span>Добавен от</span>{' '}
                <p>
                  {addedBy?.firstName + ' ' + addedBy?.lastName + ' ' + companyId?.legalName + ' на '}

                  <Moment format='DD-MM-YYYYг.'>{dateCreated}</Moment>
                </p>
              </div>
              <div className='infoblock-div'>
                <span>Допълнителна информация</span> <p>{clientComment}</p>
              </div>
            </InfoBlock>

            <InfoBlock>
              <div style={{ borderBottom: address?.length ? 'none' : '1px solid black' }}>
                <HeaderOne>Адреси</HeaderOne>
                <CreateBtn to={{ pathname: '/address' }}>Създай адрес</CreateBtn>
              </div>
              {address?.length ? (
                <CardLables>
                  <p>Град</p>
                  <p>Адрес</p>
                  <p>Помещения</p>
                  <p>виж</p>
                </CardLables>
              ) : null}
              {address?.length ? (
                [...address].reverse().map((e: Address) => (
                  <ListRow key={e._id}>
                    <div>
                      <span>Град:</span>
                      <p>{e.town}</p>
                    </div>
                    <div>
                      <span>Адрес:</span>
                      <p>{e.streetAddress}</p>
                    </div>
                    <div>
                      <span>Помещения:</span>
                      <p>{e.rooms.length}</p>
                    </div>
                    <div>
                      <Link
                        key={e._id}
                        to={{ pathname: '/addressdetails', state: e }}
                        style={{
                          display: 'flex',
                          height: '100%',
                          width: 'fit-content',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} size='2x' color={'#e45441'} />
                      </Link>
                    </div>
                  </ListRow>
                ))
              ) : (
                <HeaderOne style={{ margin: '20px' }}>Все още няма добавени адреси</HeaderOne>
              )}
            </InfoBlock>
          </DetailsContainer>
        )}
      </>
    );
  } else {
    return <Loading />;
  }
};

const mapStateToProps = (state: StateInterface) => ({
  clientState: state.clientState,
  authState: state.authState,
});
export default connect(mapStateToProps, { getSingleClient })(ClientDetails);
