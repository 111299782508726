import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { logout, getUser } from '../../reducers/authActions';
import { getAllClients } from '../../reducers/clientActions';
import { getAllItems, getModels } from '../../reducers/itemActions';
import { getData } from '../../reducers/adminActions';

const AuthChecker: React.FC<any> = ({
  authState: {
    isAuth,
    user: { isMaster, isAdmin },
  },
  logout,
  getAllClients,
  getAllItems,
  getUser,
  getModels,
  getData,
}) => {
  const token = localStorage.getItem('itlobg-token');
  useEffect(() => {
    if (isAuth) {
      getAllClients();
      getAllItems();
      getUser();
      getModels();
      if (isAdmin) {
        getData();
      }
    }
  }, [getAllClients, getAllItems, getUser, isAuth, getModels, getData, isMaster, isAdmin]);
  if (token) {
    const decoded = jwt_decode(token) as { exp: number; iat: number };

    if (decoded.exp * 1000 <= Date.now()) {
      logout();
      return <Redirect to='/login' />;
    }
  }

  return <div></div>;
};
const mapStateToProps = (state: any) => ({
  authState: state.authState,
});
export default connect(mapStateToProps, { logout, getAllClients, getAllItems, getUser, getModels, getData })(
  AuthChecker
);
