import React from 'react';
import { connect } from 'react-redux';
import { CreateBtn, HeaderOne, InfoBlock } from '../styled/common';
import { DetailsContainer } from '../styled/containers';
import { StateInterface, SingleItem, ItemModel } from '../../interfaces/StateInterfaces';
import { Link, Redirect } from 'react-router-dom';
import PrintComponent from '../layout/PrintComponent';
import Moment from 'react-moment';
const ItemDetails: React.FC<any> = ({
    itemState: { items, models },
    authState: {
        user: { firstName, lastName, isMaster },
    },
    clientState: { clients },
    location: { state },
}) => {
    const checkMessure = (messure: number, model: any) => {
        return messure >= model.minR * 0.9 && messure <= model.maxR * 1.1;
    };
    if (!items.length || !models.length) {
        return <Redirect to={{ pathname: '/' }} />;
    }

    const item: SingleItem = items.filter((e: any) => e._id === state._id)[0];
    const model: ItemModel = models.filter((e: ItemModel) => e.modelNumber === item.modelNumber)[0];

    const {
        modelNumber,
        serialNumber,
        firstMessure,
        secondMessure,
        thirdMessure,
        testCompleted,
        qCheck,
        itemComment,
        clientId,
        createdBy,
        dateCreated,
        deliveryDate,
    } = item;
    const {
        length,
        width,
        heaterWidth,
        heaterGap,
        minR,
        maxR,
        heatingArea,
        nominalI,
        nominalPower,
        connectorCount,
        connectorType,
        wireCount,
        wireType,
    } = model;
    const client = clients.filter((e: any) => e._id === clientId)[0];

    return (
        <>
            <HeaderOne style={{ marginBottom: '30px' }}> Нагревател </HeaderOne>
            <DetailsContainer>
                <InfoBlock>
                    <div className='infoblock-div'>
                        <HeaderOne>Данни за нагревателния елемент</HeaderOne>
                        {isMaster && <CreateBtn to={{ pathname: '/item', state: item }}>Редактирай</CreateBtn>}
                        <PrintComponent
                            data={{
                                modelNumber,
                                serialNumber,
                                length,
                                width,
                                nominalPower,
                                nominalI,
                                firstMessure,
                                secondMessure,
                                thirdMessure,
                                minR,
                                maxR,
                                qCheck,
                            }}
                        />
                    </div>

                    <div className='infoblock-div'>
                        <span>Модел</span> <p>{modelNumber}</p>
                    </div>
                    <div className='infoblock-div'>
                        <span>Сериен номер </span> <p>{serialNumber}</p>
                    </div>
                    <div
                        className='infoblock-div'
                        style={{
                            color: firstMessure ? (checkMessure(firstMessure, model) ? 'green' : 'red') : '',
                            fontWeight: 'bold',
                        }}
                    >
                        <span>Първо измерване</span> <p>{firstMessure}</p>
                    </div>
                    <div
                        className='infoblock-div'
                        style={{
                            color: secondMessure ? (checkMessure(secondMessure, model) ? 'green' : 'red') : '',
                            fontWeight: 'bold',
                        }}
                    >
                        <span>Второ измерване</span> <p>{secondMessure}</p>
                    </div>
                    <div
                        className='infoblock-div'
                        style={{
                            color: thirdMessure ? (checkMessure(thirdMessure, model) ? 'green' : 'red') : '',
                            fontWeight: 'bold',
                        }}
                    >
                        <span>Трето измерване</span> <p>{thirdMessure}</p>
                    </div>
                    <div className='infoblock-div'>
                        <span>Тестване</span>{' '}
                        <p style={{ color: testCompleted ? 'green' : 'red', fontWeight: 'bold' }}>
                            {testCompleted ? 'Завършено' : 'Не завършено'}
                        </p>
                    </div>
                    <div className='infoblock-div'>
                        <span>Състояние</span>{' '}
                        <p style={{ color: testCompleted ? (qCheck ? 'green' : 'red') : 'red', fontWeight: 'bold' }}>
                            {qCheck === undefined ? 'Незавършен тест' : qCheck ? 'ГОДЕН' : 'БРАК'}
                        </p>
                    </div>
                    <div className='infoblock-div'>
                        <span>Добавен от</span>
                        <p>
                            {createdBy.firstName + ' ' + createdBy.lastName}{' '}
                            <Moment format='DD.MM.YYYY - HH:mm ч.' date={dateCreated} />{' '}
                        </p>
                    </div>
                    <div className='infoblock-div'>
                        <span>Дата на зачисляване</span>
                        <p>
                            <Moment format='DD.MM.YYYY г.' date={deliveryDate} />{' '}
                        </p>
                    </div>
                    <div className='infoblock-div'>
                        <span>Клиент</span>{' '}
                        {clientId ? (
                            <Link
                                style={{
                                    display: 'flex',
                                    height: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    color: '#e45441',
                                }}
                                to={{ pathname: '/clientdetails', state: { id: clientId } }}
                            >
                                {' '}
                                {client?.clientLegalName
                                    ? client?.clientLegalName
                                    : client?.firstName + ' ' + client?.lastName}{' '}
                            </Link>
                        ) : (
                            'Не е добавен към клиент'
                        )}{' '}
                    </div>
                    <div className='infoblock-div'>
                        <span>Допълнителна информация</span> <p>{itemComment}</p>
                    </div>
                </InfoBlock>
                <InfoBlock className='model-info'>
                    <div className='infoblock-div'>
                        <HeaderOne>Данни за модела</HeaderOne>
                    </div>
                    <div className='infoblock-div'>
                        <span>Модел</span> <p>{modelNumber}</p>
                    </div>
                    <div className='infoblock-div'>
                        <span>Размери (ДхШ)</span> <p>{length + 'cm х ' + width}cm.</p>
                    </div>

                    <div className='infoblock-div'>
                        <span>Съпротивление</span> <p>{minR + 'Ω - ' + maxR + 'Ω (±10%)'}</p>
                    </div>
                    <div className='infoblock-div'>
                        <span>Номинална мощност</span> <p>~{nominalPower}watt</p>
                    </div>
                    <div className='infoblock-div'>
                        <span>Номинален ток</span> <p>{nominalI}A</p>
                    </div>
                    <div className='infoblock-div'>
                        <span>Нагревателна площ</span> <p>{heatingArea.toFixed(2)} кв.м.</p>
                    </div>
                    <div className='infoblock-div'>
                        <span>Ширина на нагревателната лента</span> <p>{heaterWidth}mm</p>
                    </div>
                    <div className='infoblock-div'>
                        <span>Стъпка</span> <p>{heaterGap}mm</p>
                    </div>
                    <div className='infoblock-div'>
                        <span>Връзки и проводници</span>{' '}
                        <p>
                            {connectorCount}бр - {connectorType}, {wireCount}бр - {wireType}
                        </p>
                    </div>
                </InfoBlock>
            </DetailsContainer>
        </>
    );
};

const mapStateToProps = (state: StateInterface) => ({
    itemState: state.itemState,
    authState: state.authState,
    clientState: state.clientState,
});

export default connect(mapStateToProps, {})(ItemDetails);
