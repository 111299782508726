import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { changePassword } from '../../reducers/authActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormGroup, FormField, InputLable, FormSubmit, FormContainer } from '../styled/forms';
import { HeaderOne } from '../styled/common';
const ChangePassword: React.FC<any> = ({ match, changePassword, history }) => {
  const [pass, setPass] = useState({
    password: '',
    rePassword: '',
  });
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPass({ ...pass, [e.currentTarget.name]: e.target.value });
  };
  const submitHandler = (event: React.MouseEvent) => {
    if (pass.password !== pass.rePassword) {
      toastr.error('Паролите не съвпадат', '');
    } else if (pass.password.length < 6) {
      toastr.error('Паролата трябва да е минимум 6 символа', '');
    } else {
      changePassword({ ...pass, token: match.params.token }, history);
    }
  };
  return (
    <>
      <HeaderOne>Смяна на парола</HeaderOne>
      <FormContainer>
        <FormGroup>
          <InputLable>Парола</InputLable>
          <FormField type='password' name='password' value={pass.password} onChange={onChangeHandler} />
        </FormGroup>
        <FormGroup>
          <InputLable>Потвърди парола</InputLable>
          <FormField type='password' name='rePassword' value={pass.rePassword} onChange={onChangeHandler} />
        </FormGroup>
        <FormGroup>
          <FormSubmit type='button' onClick={submitHandler}>
            Смени парола
          </FormSubmit>
        </FormGroup>
      </FormContainer>
    </>
  );
};

export default withRouter(connect(undefined, { changePassword })(ChangePassword));
