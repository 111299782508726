import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createRoom, editRoom } from '../../reducers/clientActions';
import { FormContainer, FormField, FormGroup, InputLable, FormSubmit, FormTextArea, Required } from '../styled/forms';
import { HeaderOne } from '../styled/common';
import { dataTrim } from '../../utils/formDataTrim';
const CreateRoom: React.FC<any> = ({
    createRoom,
    editRoom,
    location: { state },
    clientState: {
        singleClient: { _id },
    },
}) => {
    const editData = state.data;
    const addressId = state.addressId;
    const [id, setId] = useState('');
    const [formData, setFormData] = useState(
        editData || {
            _id: '',
            name: '',
            area: '',
            volume: '',
            roomType: '',
            floorType: '',
            netType: '',
            roomComment: '',
            addressId,
            clientId: _id,
        }
    );

    if (!_id) {
        return <Redirect to={{ pathname: '/' }} />;
    }
    if (id) {
        return <Redirect to={{ pathname: '/roomdetails', state: id }} />;
    }
    const onchangeHander = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    return (
        <>
            <FormContainer
                onSubmit={(e) => {
                    e.preventDefault();
                    if (editData) {
                        editRoom(dataTrim(formData), setId);
                    } else {
                        createRoom(dataTrim(formData), setId);
                    }
                }}
            >
                <HeaderOne> {editData ? 'Промяна на данните' : 'Създаване на помещение'} </HeaderOne>
                <FormGroup>
                    <InputLable>
                        Наименование <Required>*</Required>
                    </InputLable>
                    <FormField name='name' onChange={onchangeHander} value={formData.name} />
                </FormGroup>
                <FormGroup>
                    <InputLable>
                        Тип <Required>*</Required>
                    </InputLable>
                    <FormField name='roomType' onChange={onchangeHander} value={formData.roomType} />
                </FormGroup>

                <FormGroup>
                    <InputLable>
                        Обем <Required>*</Required>
                    </InputLable>
                    <FormField name='volume' onChange={onchangeHander} value={formData.volume} />
                </FormGroup>
                <FormGroup>
                    <InputLable>
                        Квадратура <Required>*</Required>
                    </InputLable>
                    <FormField name='area' onChange={onchangeHander} value={formData.area} />
                </FormGroup>
                <FormGroup>
                    <InputLable>Допълнителна информация</InputLable>
                    <FormTextArea name='roomComment' onChange={onchangeHander} value={formData.roomComment} />
                </FormGroup>

                <FormSubmit>{editData ? 'Запази промените' : 'Създай помещение'}</FormSubmit>
            </FormContainer>
        </>
    );
};
const mapStateToProps = (state: any) => ({
    clientState: state.clientState,
});
export default connect(mapStateToProps, { createRoom, editRoom })(CreateRoom);
