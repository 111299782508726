import React from 'react';
import logo from '../../assets/images/eu.jpg';
import { AboutContainer } from '../styled/containers';
const About = () => {
    return (
        <AboutContainer>
            <img src={logo} alt='' />
            <p>
                Този софтуер е създаден с финансовата подкрепа на Оперативна програма „Иновации и конкурентоспособност“
                2014-2020, съфинансирана от Европейския съюз чрез Европейския фонд за регионално развитие по проект
                BG16RFOP002-2.024-0700-C01 „Създаване на ново предприятие за производство на високо-ефективни и
                екологични системи за отопление“. Цялата отговорност за съдържанието му се носи от „Иновативни
                технологии за лъчисто отопление“ ООД и при никакви обстоятелства не може да се приема, че информацията в
                него отразява официалното становище на Европейския съюз и Управляващия орган.
            </p>
        </AboutContainer>
    );
};

export default About;
