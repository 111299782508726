import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Search = styled.input`
    font-family: 'Montserrat', sans-serif !important;
    padding: 8px;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.secondary};
    width: 100%;
    max-width: 300px;
    &::placeholder {
        color: ${({ theme }) => theme.secondary};
        font-size: 14px;
    }
`;
export const ShowOnPageContainer = styled.div`
    font-family: 'Montserrat', sans-serif !important;
    height: 40px;
    display: flex;
    gap: 10px;
    @media (max-width: 768px) {
        justify-content: center;
    }
`;
export const ShowOnPageBtn = styled.button`
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    color: ${({ theme }) => theme.light};
    background-color: ${({ theme }) => theme.secondary};
    &.active {
        background-color: ${({ theme }) => theme.light};
        border: 1px solid ${({ theme }) => theme.secondary};
        color: ${({ theme }) => theme.secondary};
    }
`;

export const CreateBtn = styled(Link)`
    padding: 12px 24px;

    border: none;
    text-decoration: none;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 22px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.light};
    width: fit-content;
    max-height: 65px;
    height: fit-content;
    vertical-align: middle;
`;

export const HeaderOne = styled.h1`
    color: ${({ theme }) => theme.primary};
    min-height: 45px;
    font-size: 42px;
`;
export const HeaderTwo = styled.h2`
    color: ${({ theme }) => theme.primary};
`;
export const InfoBlock = styled.div`
    display: flex;
    width: 100%;
    -webkit-box-shadow: -1px 1px 9px 1px rgba(0, 0, 0, 0.42);
    box-shadow: -1px 1px 9px 1px rgba(0, 0, 0, 0.42);
    flex-direction: row;
    &:first-child,
    &.model-info {
        & > div {
            &:nth-child(even) {
                background-color: #ebecf0;
            }
        }
    }

    flex-wrap: wrap;
    & > .infoblock-div {
        flex-direction: column;
    }
    & > div {
        width: 100%;
        display: flex;

        font-size: 18px;
        padding: 10px 20px;
        justify-content: space-between;

        /* 
    
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black; */
        /* &:last-child {
      border-bottom: 1px solid black;
    } */
        &:first-of-type {
            flex-direction: row;
        }
    }
    & span {
        display: inline-block;
        margin-right: 5px;
        color: gray;
        font-family: 'Yanone Kaffeesatz', sans-serif;
        font-size: 22px;
        padding: 5px 0;
    }

    @media (min-width: 768px) {
        width: 50%;

        & div {
            width: 100%;
        }
        & .infoblock-div {
            flex-direction: column;
        }
        justify-content: flex-start;

        flex-direction: column;
    }
`;
export const ListRow = styled.div`
    &:nth-child(odd) {
        background-color: #ebecf0;
    }
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    & span {
        display: block;
    }
    & span,
    div {
    }
    & div {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        & p {
            margin-top: -2px;
        }
        &:not(:last-child) {
            border-bottom: 1px solid black;
        }
    }

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        & div {
            max-width: 25%;

            justify-content: space-between;
            &:not(:last-child) {
                border-bottom: none;
            }
            &:last-child {
                justify-content: flex-end;
            }
        }
        flex-direction: row;
        & span {
            display: none;
        }
    }
`;
export const DataBtn = styled.button`
    padding: 12px 24px;
    font-size: 20px;
    border: none;
    text-decoration: none;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 22px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.light};
    width: fit-content;
`;
export const DataBtnContainer = styled.div`
    display: flex;
    gap: 20px;
`;
export const Switch = styled.label`
    /* The switch - the box around the slider */

    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    /* Hide default HTML checkbox */
    & input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    & span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #627894;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
    }

    & span:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    & input:checked + span {
        background-color: ${({ theme }) => theme.secondary};
    }

    & input:focus + span {
        box-shadow: 0 0 1px #2196f3;
    }

    & input:checked + span:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
`;

export const BackBtn = styled(Link)``;

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    & > a {
        display: inline-block;
        margin-right: auto;
    }
    & > h1 {
        margin-right: auto;
    }
`;

export const TableBody = styled.table`
    width: 100%;
    & tr {
        height: 36px;
        &:nth-child(odd) {
            background-color: lightgray;
        }
    }
`;
export const PrintBtn = styled.button`
    padding: 12px 24px;
    font-size: 20px;
    border: none;
    text-decoration: none;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 22px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.light};
    width: fit-content;
    max-height: 65px;
`;
